let BACKEND_SERVER = null;
if (process.env.REACT_APP_BACKEND_SERVER) {
  BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
} else {
  // BACKEND_SERVER = "https://talent-api.savabyte.io/api/talent";
  BACKEND_SERVER = "https://savastech.app/api/v2/talent";
}

export const API_SERVER = BACKEND_SERVER;

// roles
export const ADMIN = 1;
export const USER = 2;
