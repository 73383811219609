import { configureStore } from "@reduxjs/toolkit"
import { NavReducer, SideBarReducer, UserSlice, ModalSlice, QuestionSlice, HiringSlice, TalentRequestSlice } from "../features/"


export const store = configureStore({
  reducer: {
    nav: NavReducer,
    sidebar: SideBarReducer,
    user: UserSlice,
    modal: ModalSlice,
    questionaire: QuestionSlice,
    hiringPreference: HiringSlice,
    talentRequest: TalentRequestSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})