// @ts-nocheck
import React from "react";
import { FaEdit } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { TiTick } from "react-icons/ti";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const MyWorkPreference = () => {
    const { hiring_preference } = useSelector((store) => store.user.profiledata);
    const navigate = useNavigate();
    const preferences = [hiring_preference];
    console.table(JSON.stringify(preferences));
    const isEdit = true;
    const handleUpdate = (isEdit) => {
        navigate(`/UpIZJyywQUSZXesLFGxPYg/${isEdit}`)
    }

    return (
        <>
            <div className="bg-gray-200 rounded p-5 m-2">
                <div className="flex justify-between">
                    <div className="flex flex-row">
                        <h3 className="text-black text-2xl font-semibold mt-4">Hiring Preference</h3>
                        {
                            !hiring_preference &&
                            <Link to="/UpIZJyywQUSZXesLFGxPYg"
                                className="px-3 text-2xl hover:text-3xl mt-4"
                                title="add hiring preference">
                                <IoMdAdd />
                            </Link>
                        }
                    </div>
                    <p className="text-2xl font-bold text-blue-900 "></p>

                    {hiring_preference &&
                        <FaEdit
                            onClick={() => handleUpdate(isEdit)}
                            className="text-blue-800 hover:text-xl" title="update" />
                    }
                </div>

                <div className="flex flex-col space-y-3">
                    {preferences?.map((item, index) => (
                        <div key={index}>
                            <div className="py-4 flex justify-start items-center space-x-3">
                                <span className="text-gray-900 font-semibold">
                                    Actively Job Seeking :
                                </span>
                                <label className="relative inline-flex items-center mr-5 cursor-pointer">
                                    <input
                                        type="checkbox"
                                        name="actively_job_seeking"
                                        className="sr-only peer"
                                        checked={item?.actively_job_seeking}
                                    />
                                    <div
                                        className="w-12 h-6 bg-gray-200 rounded-full peer ring-1 ring-blue-800
                                                    peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                                                    after:absolute after:top-0.5 after:left-[2px] after:bg-white
                                                    after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                                                    dark:border-gray-600 peer-checked:bg-blue-800"
                                    ></div>
                                </label>
                            </div>

                            <div className="flex flex-col space-y-3">
                                <div className="w-full flex ">
                                    <span className="font-semibold w-2/12 text-xl">
                                        Stack(s):
                                    </span>
                                    {item?.tech_stack?.map((stack, index) => (
                                        <span
                                            key={index}
                                            className="leading-snug px-2 flex items-center"
                                        >
                                            <TiTick className="text-blue-800" /> {stack.toUpperCase()}
                                            {"  "}
                                        </span>
                                    ))}
                                </div>
                                <div className="w-full flex">
                                    <span className="font-semibold w-2/12">Role(s):</span>
                                    {item?.roles?.map((role, index) => (
                                        <span
                                            key={index}
                                            className="leading-snug px-2 flex items-center"
                                        >
                                            <TiTick className="text-blue-800" /> {role.toUpperCase()}{" "}
                                        </span>
                                    ))}
                                </div>
                                <div className="w-full flex">
                                    <span className="font-semibold w-2/12">
                                        Location Type(s)):
                                    </span>
                                    {item?.model_of_work?.map((work, index) => (
                                        <span
                                            key={index}
                                            className="leading-snug px-2 flex items-center"
                                        >
                                            <TiTick className="text-blue-800" />
                                            {work.toUpperCase()}{" "}
                                        </span>
                                    ))}
                                </div>
                                <div className="w-full flex">
                                    <span className="font-semibold w-2/12">Job Type(s)):</span>
                                    {item?.job_type?.map((job, index) => (
                                        <span
                                            key={index}
                                            className="leading-snug px-2 flex items-center"
                                        >
                                            <TiTick className="text-blue-800" /> {job.toUpperCase()}{" "}
                                        </span>
                                    ))}
                                </div>
                                <div className="w-full flex">
                                    <span className="font-semibold w-2/12">
                                        Job Engagement(s)):
                                    </span>
                                    {item?.job_engagement?.map((type, index) => (
                                        <span
                                            key={index}
                                            className="leading-snug px-2 flex items-center"
                                        >
                                            <TiTick className="text-blue-800" />
                                            {type.toUpperCase()}{" "}
                                        </span>
                                    ))}
                                </div>
                                <div className="w-full flex">
                                    <span className="font-semibold w-2/12">
                                        Notice Period(s)):
                                    </span>
                                    <span className="leading-snug px-2 flex items-center">
                                        {item?.notice_period?.toUpperCase()}{" "}
                                    </span>
                                </div>
                                <div className="w-full flex">
                                    <span className="font-semibold w-2/12">
                                        Expected Salary:
                                    </span>
                                    <span className="leading-snug px-2 flex items-center">
                                        {item?.expected_salary?.toUpperCase()}{" "}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default MyWorkPreference;
