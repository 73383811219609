import React from 'react'
import { MyEducation, MyLanguages } from '../../../pages/Profile/sections'
const Education = () => {
    return (

        <div className="w-full lg:px-3">
            <div className="flex flex-col lg:flex-row mt-4 sm:space-y-4 md:space-y-0">
                <div className="text-black p-4 rounded w-full lg:w-9/12 bg-gray-200 lg:mr-4 lg:mb-0">
                    <MyEducation />
                </div>
                <div className="text-black rounded w-full lg:w-3/12 bg-gray-200 lg:mt-0 md:mt-0 sm:mt-3 lg:ml-4">
                    <MyLanguages /> 
                </div>
            </div>
        </div>
    )
}

export default Education