// @ts-nocheck
import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup'
import { TiTickOutline } from "react-icons/ti";
import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { IoMdAdd } from "react-icons/io"
import { toggleAwardsModal, toggleCertificationModal, toggleEducationModal } from '../../../../features/layout/ModalSlice';
import Modal from '../../../../components/common/Modal';
import { addAward } from '../../../../features/profile/certAwardsSlice';
import ErrorBoundary from '../../../../constants/ErrorBoundary';
import Award from '../../../../components/profile/certificationawards/Award';
import { ToastContainer } from "react-toastify";


const Awards = () => {


    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isAwardsModalOpen } = useSelector((store) => store.modal);
    const { awards } = useSelector((store) => store.user.profiledata);


    const title = "Add New Award"
    const initialValues = {
        name: "",
        standard: "",
        place: "",
        issued: "",
    };

    const onSubmit = (values) => {

        //post 
        dispatch(addAward({ name: values.name, institution: values.standard, country: values.place, date: values.issued }))
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Awards Name is required"),
        standard: Yup.string().required("Standard Name is required"),
        place: Yup.string().required("Place of Award is required"),
        issued: Yup.mixed().required("Issued Date is required"),
    });

    return (
        <>
            <ToastContainer />
            {isAwardsModalOpen ? (
                <Modal
                    showModal={showModal}
                    setShowModal={toggleAwardsModal}
                    title={title}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="name">Name:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Certification Name"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="standard">Institution:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="standard"
                                            placeholder="Institution"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="standard"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="place">Country:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="place"
                                            placeholder="place of awarded"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="place"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-row space-x-3'>
                                    <div className="flex flex-row w-full p-3 space-x-3">
                                        <label className='w-20 mt-2' htmlFor="Issued">Issued:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="issued"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="issued"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-3 pt-4 mt-3">
                                    <button
                                        onClick={() => dispatch(setShowModal())}
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <button
                                            onClick={() => dispatch(toggleAwardsModal())}
                                            className="flex flex-row space-x-1"
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </button>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>processing</span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}

            <div className='flex flex-row m-4 space-x-3'>
                <h3 className='text-2xl font-semibold text-black'>Awards</h3>
                <button
                    onClick={() => dispatch(toggleAwardsModal())}
                    className="px-3 text-2xl fa hover:text-3xl" title='add new'><IoMdAdd /></button>
            </div>
            {
                <ErrorBoundary>
                    <Award awards={awards} />
                </ErrorBoundary>
            }

        </>
    )
}

export default Awards