// @ts-nocheck
function ErrorPage({message}) {
    
  return (
    <>
      <div className="min-w-screen  bg-blue-100 flex items-center p-5 lg:p-20 overflow-hidden relative rounded">
        <div className="flex-1 min-h-full min-w-full rounded-3xl bg-white shadow-xl p-10 lg:p-20 text-gray-800  md:flex items-center text-center md:text-left">
          <div className="w-full h-fit">
            <div className="mb-10 md:mb-20 text-gray-600 font-light">
              <h1 className="font-black uppercase text-3xl lg:text-5xl text-yellow-400 mb-6">
                You seem to be lost!
              </h1>
              <p className="text-xl font-semibold font-mono">
                The page you're looking for isn't available.
              </p>
              <p className="text-xl font-semibold font-mono underline underline-offset-2">
                {message}
              </p>
            </div>
          </div>
        </div>
        <div className="w-64 md:w-96 h-96 md:h-full bg-blue-200 bg-opacity-30 absolute -top-64 md:-top-96 right-20 md:right-32 rounded-full pointer-events-none -rotate-45 transform"></div>
        <div className="w-96 h-full bg-yellow-200 bg-opacity-20 absolute -bottom-96 right-64 rounded-full pointer-events-none -rotate-45 transform"></div>
      </div>
    </>
  );
}

export default ErrorPage;
