import React from "react";

const FallBackLoader = () => {
  return (
    <div className="flex items-center justify-center h-screen bg-blue-400">
      <div className="animate-spin loader ease-linear rounded-full border-dashed border-8 border-t-8 border-yellow h-32 w-32"></div>
    </div>

    // Alternative Loader
    //     <div className="flex items-center justify-center h-screen bg-blue-400">
    //     <div className="relative">
    //       <div className="absolute inset-0 bg-white opacity-25 rounded-full"></div>
    //       <div className="absolute inset-0 transform animate-pulse">
    //         <div className="animate-spin ease-linear rounded-full border-dashed border-8 border-t-8 border-yellow h-32 w-32"></div>
    //       </div>
    //     </div>
    //   </div>
  );
};

export default FallBackLoader;
