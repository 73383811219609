import React, { useState } from 'react'
import { FaSpinner } from 'react-icons/fa';
import { IoMdAdd } from "react-icons/io"
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup'
import { TiTickOutline } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
import Modal from '../../../../components/common/Modal';
import { toggleEducationModal } from '../../../../features/layout/ModalSlice';
import { addWorkExperience } from '../../../../features/profile/experienceSlice';
import Select from 'react-select'
import ErrorBoundary from '../../../../constants/ErrorBoundary';
import SingleEducation from '../../../../components/profile/education/SingleEducation';
import { addEducation } from '../../../../features/profile/educationSlice';
import { ToastContainer } from "react-toastify";

const MyEducation = () => {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isEducationModalOpen } = useSelector((store) => store.modal);
    const { education } = useSelector((store) => store.user.profiledata);
    const title = "Education"
    const options = [
        { value: 'Primary', label: 'Primary' },
        { value: 'Secondary', label: 'Secondary' },
        { value: 'University', label: 'University' },
        { value: 'Tertiary', label: 'Tertiary' },
    ]
    const initialValues = {
        institution: "",
        level: '',
        course: "",
        country: "",
        end_date: "",
        start_date: ""
    };

    const onSubmit = (values) => {

        //missing course
        dispatch(addEducation({ level: values.level, institution: values.institution, country: values.country, start_date: values.start_date, end_date: values.end_date }))
    }
    const validationSchema = Yup.object({
        level: Yup.string().required("Level is required"),
        country: Yup.string().required("Country Name is required"),
        institution: Yup.string().required("institution Name is required"),
        course: Yup.string().required("Course Name is required"),
        start_date: Yup.mixed().required("StartDate is required"),
        end_date: Yup.mixed().required("EndDate is required"),
    });

    return (
        <>
            <ToastContainer />
            {isEducationModalOpen ? (
                <Modal
                    showModal={showModal}
                    setShowModal={toggleEducationModal}
                    title={title}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ values, isSubmitting, setFieldValue }) => (
                            <Form>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="course">Course:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="course"
                                            placeholder="Course"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="course"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="level">Level:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="level"
                                            component={Select}
                                            options={options}
                                            onChange={(selectedOption) =>
                                                setFieldValue('level', selectedOption.value)
                                            }
                                            className="w-full"
                                        />
                                        <ErrorMessage
                                            name="level"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="name">Institution:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            type="text"
                                            name="institution"
                                            placeholder="Institution Name"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="institution"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="country">Country:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="country"
                                            placeholder="country"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="country"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-row space-x-3'>
                                    <div className="flex flex-row w-1/2 p-3 space-x-3">
                                        <label className='w-20 mt-2' htmlFor="end_date">To:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="end_date"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="end_date"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-1/2 p-3 space-x-3">
                                        <label className='mt-2' htmlFor="start_date">From:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="start_date"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="start_date"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-3 pt-4 mt-3">
                                    <button
                                        onClick={() => dispatch(setShowModal())}
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <button
                                            onClick={() => dispatch(toggleEducationModal())}
                                            className="flex flex-row space-x-1"
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </button>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>processing</span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}

            <div className='flex flex-row m-4 space-x-3'>
                <h3 className='mt-4 text-2xl font-semibold text-black'>Education</h3>
                <button
                    onClick={() => dispatch(toggleEducationModal())}
                    className="px-3 mt-4 text-2xl hover:text-3xl" title='add certification'
                ><IoMdAdd /></button>
            </div>
            {
                <ErrorBoundary>
                    <SingleEducation education={education} />
                </ErrorBoundary>
            }


        </>
    )
}

export default MyEducation