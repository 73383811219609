// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Helmet from '../../common/Helmet';
import { AiOutlineFilePdf } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux';
import { getOfferContract } from '../../../features/talentRequest/talentRequestSlice';
import customFetch from '../../../Utils/hooks/axios';
const OfferContract = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const data = useParams()
  const { id } = data;
  const { talent_contract } = useSelector((store) => store.talentRequest.nda) || {};

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDelete = () => {
    setSelectedFile(null);
  };
  useEffect(() => {
    dispatch(getOfferContract(id))
  }, [dispatch, id])

  const handleSubmit = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('client_nda', selectedFile);

      customFetch.post(`/dashboard/talent/match/${id}/client_nda`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch(error => {
          const errorMessage =
            error.response.data.error || error.response.data.message || error.message;
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="">
        <div className="p-3 ">
          <Helmet 
            title="A formal agreement is presented to the selected tech talent, outlining the terms and conditions of their engagement, including compensation, project details, and contractual obligations, ensuring a clear and mutually beneficial working arrangement." />
          <div className='bg-white p-3 mt-3'>
            <div className="flex flex-col p-3bg-white">
              <div className='underline'>
                <a href={talent_contract} target="_blank" rel="noopener noreferrer">

                  <div className='flex space-x-3 cursor-pointer'>
                    <span><AiOutlineFilePdf className='text-red-600 text-2xl' /></span>
                    <span className='text-sm font-medium text-gray-900'>Unsigned NDA</span>
                  </div>
                </a>
              </div>
              <div className="w-5/6 space-y-3">
                <label htmlFor="file_input" className="block mb-3 mt-3 text-sm font-medium text-gray-900">
                  Signed Client NDA
                </label>
                <div className="flex">
                  <input
                    id="file_input"
                    type="file"
                    className="block w-full text-sm text-blue-900 border border-blue-800 rounded-lg cursor-pointer bg-white focus:outline-none"
                    onChange={handleFileChange}
                  />
                  {selectedFile && (
                    <div className="mt-3 px-3">
                      <RiDeleteBin5Fill size={24} onClick={handleDelete} className="text-red-600" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 p-3">
              <button className="w-full font-semibold bg-blue-800 text-white py-2 px-4 rounded-md hover:bg-blue-900 focus:outline-none" onClick={handleSubmit}>
                Upload Signed NDA
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferContract;

