import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import Modal from "../../common/Modal";
import { TiTickOutline } from "react-icons/ti";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import Background from "../../../assets/images/ss.png";
import { ToastContainer } from "react-toastify";
import MultiSelectField from "../../common/MultiSelectField";
import techstacks from "../../../Utils/data/techstacks";
import {
    updateProject,
    deleteProject
} from "../../../features/profile/projectSlice";
import { toggleProjectUpdate } from "../../../features/layout/ModalSlice";
import DeleteModal from "../../common/DeleteModal";
import { FaSpinner } from "react-icons/fa";


const Project = ({ projects }) => {
    const { isProjectUpdate } = useSelector((store) => store.modal);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [data, setData] = useState({});
    const dispatch = useDispatch();

    const { _id, name, description, link, technologies, start_date, end_date } =
        data;
    const title = "Update Project ";
    let id = _id;
    const initialValues = {
        name: name,
        description: description,
        link: link,
        start_date: start_date,
        end_date: end_date,
        tech: [],
    };


    const handleEdit = (id) => {
        const project = projects.find((project) => project._id === id);
        if (project !== undefined) {
            setData(project);
        }
    };
    const handleDelete = (id) => {
        setIsSubmitting(!isSubmitting)
        const project = projects.find((project) => project._id === id);
        if (project !== undefined) {

            if (project !== undefined) {

                dispatch(deleteProject({ data: { id } }))
                setTimeout(() => {
                    setIsDeleteModalOpen(!isDeleteModalOpen);
                }, 2000);
            }
        }
    };
    const handleDeleteModal = (event) => {
        event.stopPropagation();
        setIsDeleteModalOpen(!isDeleteModalOpen);
    }

    const onSubmit = (values) => {

        //PATCH
        const techStacks = values.tech.map((option) => option.value);
        dispatch(
            updateProject({
                data: {
                    name: values.name,
                    description: values.description,
                    link: values.link,
                    technologies: techStacks,
                    start_date: values.start_date,
                    end_date: values.end_date,
                },
                id: id,
            })
        )
    };

    if (!projects || projects.length === 0) {
        return (
            <div className="text-sm leading-5 text-center text-red-400">
                Add New Project
            </div>
        );
    }
    const techstack = projects.map((project) => {
        return project.technologies.map((technology) => {
            return technology.toUpperCase();
        });
    });

    return (
        <>
            <ToastContainer />
            <div className="p-3">
                {projects.slice(0, 3).map((item, index) => (
                    <>
                        <div
                            className="w-full m-2 mb-3 bg-gray-200 border rounded-lg lg:max-w-full lg:flex"
                            key={item._id} >
                            <div
                                className="flex-none h-48 overflow-hidden text-center bg-cover rounded-t lg:h-auto lg:w-48 lg:rounded-t-none lg:rounded-l"
                                style={{ backgroundImage: `url(${Background})` }}
                                title="Woman holding a mug"
                            ></div>
                            <div className="flex flex-col justify-between leading-normal bg-gray-200 lg:border-l-0 lg:border-gray-400 lg:p-4">
                                <div className="mb-8">
                                    <div className="flex flex-row space-x-3">
                                        <p className="flex items-center text-xl font-bold text-black">
                                            {item.name}
                                        </p>
                                        <div className="flex items-center justify-center space-x-3">
                                            <span
                                                onClick={() => {
                                                    dispatch(toggleProjectUpdate(true));
                                                    handleEdit(item._id);
                                                }}
                                                className="text-xl text-blue-800 cursor-pointer hover:text-2xl"
                                            >
                                                <FiEdit />
                                            </span>
                                            <span
                                                onClick={handleDeleteModal}
                                                className="text-xl text-red-600 cursor-pointer hover:text-2xl">
                                                <RiDeleteBin5Line />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="mb-2 space-x-2 text-xl font-bold text-gray-900">
                                        {techstack[index].map((technology, idx) => (
                                            <span
                                                key={idx}
                                                className="px-3 py-1 text-sm text-white bg-yellow-400 rounded-full hover:bg-yellow-100"
                                            >
                                                {technology}
                                            </span>
                                        ))}
                                    </div>
                                    <p className="text-base text-gray-700">{item.description}</p>
                                </div>
                                <div>
                                    <a href={item.link} className="text-blue-800 underlined">
                                        {item.link}
                                    </a>
                                </div>
                            </div>
                        </div>
                        {/* DELETE */}
                        {isDeleteModalOpen ? (
                            <DeleteModal title="Project">
                                <div className="flex items-center justify-between p-4">
                                    <button
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <span
                                            onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                                            className="flex flex-row space-x-1 font-semibold hover:text-red-700 hiver:font-bold "
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </span>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>Processing</span>
                                            </div>
                                        ) : (
                                            <button
                                                onClick={() => handleDelete(item._id)}

                                                className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Delete</span>
                                            </button>
                                        )}
                                    </button>
                                </div>
                            </DeleteModal>
                        ) : null}
                    </>
                ))}
            </div>
            {/* Update */}
            {isProjectUpdate ? (
                <Modal title={title} setShowModal={toggleProjectUpdate}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {({ onSubmit, isSubmitting }) => (
                            <Form>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className="w-1/6 mt-2 " htmlFor="name">
                                        Title:{" "}
                                    </label>
                                    <div className="flex flex-col w-11/12 space-y-3">
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Certification Name"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className="w-1/6 mt-2 " htmlFor="standard">
                                        Description:
                                    </label>
                                    <div className="flex flex-col w-11/12 space-y-3">
                                        <Field name="description">
                                            {({ field }) => (
                                                <textarea
                                                    {...field}
                                                    id="description"
                                                    name="description"
                                                    placeholder="Enter a description"
                                                    rows={2}
                                                    className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="standard"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className="w-1/6 mt-2" htmlFor="place">
                                        Link:
                                    </label>
                                    <div className="flex flex-col w-11/12 space-y-3">
                                        <Field
                                            name="link"
                                            placeholder="place of awarded"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="place"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row space-x-3">
                                    <div className="flex flex-row w-full p-3 space-x-3">
                                        <label className="w-1/6 mt-2" htmlFor="Issued">
                                            Start Date:
                                        </label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="start_date"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="start_date"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-3">
                                    <div className="flex flex-row w-full p-3 space-x-3">
                                        <label className="w-1/6 mt-2" htmlFor="end_date">
                                            End Date:
                                        </label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="end_date"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="end_date"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row space-x-3">
                                    <div className="flex flex-row w-full p-3 space-x-3">
                                        <label className="w-1/6 mt-2" htmlFor="Issued">
                                            Tech Stacks:
                                        </label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <MultiSelectField
                                                name="tech"
                                                defaultValue={techstacks}
                                                options={techstacks}
                                            />
                                            <ErrorMessage
                                                name="tech"
                                                component="div"
                                                className="space-y-2 text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="flex items-center justify-between p-3 pt-4 mt-3">
                                    <button
                                        onClick={() => dispatch(toggleProjectUpdate())}
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <span
                                            className="flex flex-row space-x-1 hover:font-bold"
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </span>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>processing</span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}
        </>
    );
};

export default Project;
