import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaSpinner } from 'react-icons/fa';
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup'

import { useFormik } from "formik";
import { toggleProjectModal } from "../../../features/layout/ModalSlice";
import Modal from "../../common/Modal";
import { TiTickOutline } from 'react-icons/ti'
import { IoMdClose } from 'react-icons/io'
import { addUserProject } from "../../../features/profile/projectSlice";
import Project from "./Project";
import ErrorBoundary from "../../../constants/ErrorBoundary";
import MultiSelectField from "../../common/MultiSelectField";
import techstacks from "../../../Utils/data/techstacks";
import { ToastContainer } from "react-toastify";

const Projects = () => {

    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isProjectModalOpen } = useSelector((store) => store.modal);
    const { projects } = useSelector((store) => store.user.profiledata);


    const initialValues = {
        name: "",
        description: "",
        link: "",
        technologies: [],
        start_date: "",
        end_date: "",
        current: false,
    };
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Project Name is required"),
        description: Yup.string().required("Description is required"),
        link: Yup.string().required("Link Name is required"),
        technologies: Yup.array().required('Please select at least one technology'),
        start_date: Yup.mixed().required("Start Date is required"),
        end_date: Yup.mixed().required("End Date is required"),
    });

    const onSubmit = (values, onSubmitProps) => {

        const techStacks = values.technologies.map((option) => option.value);
        dispatch(addUserProject({
            name: values.name, description: values.description, link: values.link, technologies: techStacks,
            start_date: values.start_date, end_date: values.end_date
        }))
    }

    const formik = useFormik({
        initialValues,
        onSubmit,
    });

    const title = "Project"



    return (
        <>
            {/* Modal */}
            <ToastContainer />
            {isProjectModalOpen ? (
                <Modal showModal={isProjectModalOpen} setShowModal={toggleProjectModal} title={title}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ values, isSubmitting, setFieldValue }) => (
                            <Form>
                                <div className="flex flex-row justify-center w-full mt-3 space-x-4">
                                    <div className="flex w-1/3 content-baseline ">Title : </div>
                                    <div className="flex flex-col w-full space-y-2" >
                                        <Field
                                            name="name"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            placeholder="Project Name"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center w-full mt-3 space-x-4">
                                    <div className="flex w-1/3 content-baseline ">Description : </div>
                                    <div className="flex flex-col w-full space-y-2" >
                                        <Field name="description">
                                            {({ field }) => (
                                                <textarea
                                                    {...field}
                                                    id="description"
                                                    name="description"
                                                    placeholder="Enter a description"
                                                    rows={2}
                                                    className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                                />
                                            )}
                                        </Field>
                                        <ErrorMessage
                                            name="description"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center w-full mt-3 space-x-4">
                                    <div className="flex w-1/3 content-baseline ">Project Link : </div>
                                    <div className="flex flex-col w-full space-y-2" >
                                        <Field
                                            name="link"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            placeholder="project link"
                                        />
                                        <ErrorMessage
                                            name="link"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center w-full mt-3 space-x-4">
                                    <div className="flex w-1/3 content-baseline ">Start Date : </div>
                                    <div className="flex flex-col w-full space-y-2" >
                                        <Field
                                            name="start_date"
                                            type="date"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="start_date"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center w-full mt-3 space-x-4">
                                    <div className="flex w-1/3 content-baseline ">Start Date : </div>
                                    <div className="flex flex-col w-full space-y-2" >
                                        <Field
                                            name="end_date"
                                            type="date"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="end_date"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center w-full mt-3 space-x-4">
                                    <div className="flex w-1/3 content-baseline ">Tech Stacks : </div>
                                    <div className="w-full" >
                                        <MultiSelectField name="technologies" options={techstacks} />
                                        <ErrorMessage
                                            name="technologies"
                                            component="div"
                                            className="space-y-2 text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between pt-4 mt-3">
                                    <button
                                        onClick={() => dispatch(setShowModal())}
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none">
                                        <span
                                            onClick={() => dispatch(toggleProjectModal())}
                                            className='flex flex-row space-x-1'>
                                            <IoMdClose className='m-1' />
                                            <span>Cancel</span>
                                        </span>
                                    </button>
                                    {/* save data */}
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>processing</span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>

                </Modal>
            ) : null
            }

            <div className="flex flex-row justify-between w-full p-4 mt-3">
                <div>
                    <h3 className="text-xl font-bold">Projects</h3>
                </div>
                <div>
                    <button
                        onClick={() => dispatch(toggleProjectModal())}
                        className="px-4 py-2 text-sm text-white bg-blue-900 rounded hover:bg-blue-800">
                        Add Project
                    </button>
                </div>
            </div>
            {/* // projects */}
            {
                <ErrorBoundary>
                    <Project projects={projects} />
                </ErrorBoundary>
            }

        </>
    );
};

export default Projects;
