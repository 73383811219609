import React from 'react'
import Finished from './Finished'

const TalentOnboarding = () => {
  return (
    <>
      <Finished />
    </>
  )
}

export default TalentOnboarding