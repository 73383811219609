export const addTokenToLocalStorage = (token) => {
    sessionStorage.setItem('user_token', JSON.stringify(token));
};

export const removeTokenFromLocalStorage = () => {
    sessionStorage.removeItem('user_token')
    
}

export const getUserTokenFromLocalStorage = () => {
    const result = sessionStorage.getItem('user_token');
    const user_token = result ? JSON.parse(result) : null;
    return user_token;
}
export const addRegisterTokenToLocalStorage = (token) => {
    sessionStorage.setItem('register_token', JSON.stringify(token));
};

export const removeRegisterTokenFromLocalStorage = () => {
    sessionStorage.removeItem('register_token')
}
export const getRegisterTokenFromLocalStorage = () => {
    const result = sessionStorage.getItem('register_token');
    const register_token = result ? JSON.parse(result) : null;
    return register_token;
}

//confirm token
export const addConfirmTokenToLocalStorage = (token) => {
    sessionStorage.setItem('c_token', JSON.stringify(token));
};

export const getConfirmTokenFromLocalStorage = () => {
    const result = sessionStorage.getItem('c_token');
    const c_token = result ? JSON.parse(result) : null;
    return c_token;
}

//reset token
export const addResetTokenToLocalStorage = (token) => {
    sessionStorage.setItem('r_token', JSON.stringify(token.token));
};

export const getResetTokenFromLocalStorage = () => {
    const result = sessionStorage.getItem('r_token');
    const r_token = result ? JSON.parse(result) : null;
    return r_token;
}


export const addEmailSent = (data) => {
    sessionStorage.setItem('send_email', JSON.stringify(data));
}

export const removeEmailSent = () => {
    sessionStorage.removeItem('send_email');
}

export const getEmailSent = () => {
    const result = sessionStorage.getItem('send_email');
    const email_sent = result ? JSON.parse(result) : null;
    return email_sent;
}