import React from 'react'
import ReactDom from 'react-dom'
import { FaRegTrashAlt } from 'react-icons/fa';


const DeleteModal = ({ title, children }) => {

    return ReactDom.createPortal(
        <>
        <div className='h-screen  bg-opacity-75 transition-opacity md:w-[100%] w-[100%] mx-auto justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none' style={{
            zIndex: 1000,
            backgroundColor: 'rgba(0, 0, 0, .5)',
        }}  >
            <div className="inset-0 flex items-center justify-center ">
                <div className="bg-white p-6 rounded-lg shadow-lg mx-4 sm:mx-auto sm:w-[40rem] md:w-[40rem] lg:w-[40rem]">
                    <div className='flex flex-row items-center justify-center space-x-3'>
                        <div className='text-red-600 text-7xl'>
                            <FaRegTrashAlt />
                        </div>
                        <div className='flex flex-col m-4'>
                            <h3 className="space-y-3 text-2xl text-black">
                                Deleting {title}
                            </h3>
                            <span>Are You Sure You Want To Delete This Work Experience ?</span>
                        </div>
                    </div>
                    <div className="relative flex-auto">
                        {children}
                    </div>
                </div>
            </div>
            </div>
        </>,
        document.getElementById('portal')
    )
}

export default DeleteModal