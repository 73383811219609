// @ts-nocheck
import {
  TechProfile,
  Education,
  Spacer,
  AboutMe,
  WorkExperience,
  Projects,
  MyCertificationAwards, MyWorkPreference
} from "../Profile/index";

function Profile() {
  return (
    <>
      <div className="h-screen overflow-x-hidden overflow-y-scroll">
        <TechProfile />
        <Spacer />
        <AboutMe />
        <WorkExperience />
        <Education />
        <Projects />
        <MyCertificationAwards />
        <MyWorkPreference />
        <Spacer />
      </div>
    </>
  );
}

export default Profile;
