// @ts-nocheck
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const TopicResults = () => {
  const { zErxDJ3ToEaoWaagF8Urvg } = useParams();

  const { questionResults } = useSelector((state) => state.questionaire);
  const filteredData = questionResults.filter(
    (item) => item.stack_name === zErxDJ3ToEaoWaagF8Urvg
  );
  return (
    <>
      <div className="h-screen mt-20">
        <div className="p-3 bg-gray-200 rounded-md">
          <h2 className="text-3xl font-bold py-2 ">{zErxDJ3ToEaoWaagF8Urvg}</h2>
          <div className="flex flex-col">
            <div className="flex flex-wrap">
              <div className="w-full">
                {/* {filteredData ? () : ""} */}
                {filteredData.map((item) => (
                  <div key={item._id}>
                    {item.topic_scores
                      .filter((topic) => topic.topic_tittle !== null)
                      .map((topic) => (
                        <>
                          <div
                            key={topic.topic_tittle}
                            className="bg-white text-black p-2 m-2 rounded hover:cursor-pointer flex items-center justify-between"
                          >
                            <span className="font-semibold">
                              Quiz: {topic.topic_tittle}
                            </span>
                            <div className="flex mr-3">
                              <button className="bg-green-500 rounded-lg text-white px-3 py-1">
                                {topic.talent_score}/{topic.topic_points}
                              </button>
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopicResults;
