// @ts-nocheck
// customFetch.js
import axios from "axios";
import {
  getUserTokenFromLocalStorage,
  removeTokenFromLocalStorage,
} from "../../services/localStorage";

const defaultOptions = {
  baseURL: "https://savastech.app/api/v2/talent",
  // baseURL: 'https://staging.savabyte.io/api/v2/talent',
  headers: {
    "Content-Type": "application/json",
  },
};

const customFetch = axios.create(defaultOptions);

customFetch.interceptors.request.use(function (config) {
  const token = getUserTokenFromLocalStorage();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

customFetch.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      // Token has expired, redirect the user to the login page
      window.location.href = "/talent/l5T75heuRE2RYrQBOJb38Q";
      removeTokenFromLocalStorage();
    }
    return Promise.reject(error);
  },
);

export default customFetch;
