// @ts-nocheck
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom"
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from 'yup'
import { RiPencilLine } from "react-icons/ri";
import { FiPhone, FiEdit } from "react-icons/fi"
import { SlLocationPin } from "react-icons/sl"
import { CiMail } from "react-icons/ci"
import { TiTickOutline } from 'react-icons/ti'
import { IoMdClose } from 'react-icons/io'
import { ToastContainer, toast } from "react-toastify";
import Modal from "../../common/Modal";
import me from "../../../assets/images/me.png";
import {
    getUserData,
    updateUserProfile
} from "../../../features/auth/userSlice";
import {
    toggleProfileModal
} from "../../../features/layout/ModalSlice";
import { FaSpinner } from "react-icons/fa";
import moment from "moment";
import { useEffect, useState } from "react";
import customFetch from "../../../Utils/hooks/axios";
import DebugData from "../../common/DebugData";

const TechProfile = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         dispatch(getUserData());
    //     }, 1000);

    //     return () => clearInterval(interval);
    // }, [dispatch]);
    useEffect(() => {
        dispatch(getUserData())
    }, [dispatch]);

    const { account } = useSelector((store) => store.user.profiledata);
    const { avatar } = useSelector((store) => store.user.profiledata);
    const isProfileModalOpen = useSelector((store) => store.modal.isProfileModalOpen);
    const [selectedImage, setSelectedImage] = useState(null);
    const title = "Personal Information";

    if (!account) {
        navigate("/l5T75heuRE2RYrQBOJb38Q");
        return null;
    }

    const initialValues = {
        firstName: account.firstName || "",
        lastName: account.lastName || "",
        country: account.country || "",
        phoneNumber: account.phoneNumber || "",
    };

    const onSubmit = (values, { setSubmitting }) => {
        dispatch(updateUserProfile(values));
        setSubmitting(false);
    };

    const validationSchema = Yup.object({
        firstName: Yup.string().required("First name is required"),
        lastName: Yup.string().required("Last name is required"),
        country: Yup.string().required("Country is required"),
        phoneNumber: Yup.string().required("Phone number is required"),
    });

    const handleProfileUpload = (e) => {
        const file = e.target.files[0];
        setSelectedImage(file);
    };

    const uploadImage = async () => {
        try {
            if (!selectedImage) {
                return;
            }
            const formData = new FormData();
            formData.append('avatar', selectedImage);
            const response = await customFetch.post('/profile/avatar', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success("Profile Updated");
        } catch (error) {
            console.error('Error uploading the profile:', error);
        }
    };

    if (selectedImage && !avatar) {
        uploadImage();
    }

    return (
        <>

            <ToastContainer />
            <section className="relative mt-20 rounded-lg h-60 bg-profile">
                <div className="absolute flex flex-col justify-between w-full px-8 space-x-4 font-semibold bg-gray-200 border rounded-lg shadow-sm lg:flex-row my-profile">
                    <div className="lg:pl-5">
                        <div className="flex flex-col lg:flex-row">
                            <div className="relative flex flex-row items-center justify-center">
                                {/* <DebugData data={avatar}/> */}
                                <img
                                    src={avatar ? avatar : me}
                                    loading="lazy"
                                    className="w-[8rem] h-[8rem] me-top rounded-full ring-[.5rem] ring-white"
                                    alt="#profile"
                                />
                                {/* Container for the upload profile icon */}
                                <div className="flex justify-center items-center absolute upload-avatar bg-white w-[2rem] h-[2rem] rounded-full p-1">
                                    <label htmlFor="profile-upload">
                                        <FiEdit className="text-blue-500 cursor-pointer" />
                                    </label>
                                    {/* Hidden input element for handling file upload */}
                                    <input
                                        type="file"
                                        id="profile-upload"
                                        className="hidden"
                                        accept=".jpg, .jpeg, .png"
                                        onChange={handleProfileUpload}
                                    />
                                </div>
                            </div>
                            <div className="h-24 lg:h-36">
                                <div className="flex items-center justify-center align-middle flex-column lg:items-start h-36">
                                    <div className="flex flex-row">
                                        <h3 className="text-3xl font-bold lg:pl-4">
                                            {account.firstName} {account.lastName}
                                        </h3>
                                        <div className="block px-2 mt-2 lg:hidden">
                                            <button onClick={() => dispatch(toggleProfileModal())}>
                                                <FiEdit />
                                            </button>
                                        </div>
                                    </div>
                                    <p className="md:pl-3 lg:pl-5"></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="lg:p-1">
                        <div className="flex flex-row items-center justify-center space-x-8">
                            <div className="flex items-center h-36 lg:justify-end">
                                <div className="flex flex-col">
                                    <div className="flex flex-row py-2">
                                        <RiPencilLine className="m-2" />
                                        <span className="m-1 text-sm">Joined {moment(account.createdAt).format('MMMM YYYY')}</span>
                                    </div>
                                    <div className="flex flex-row">
                                        <FiPhone className="m-2" />
                                        <span className="m-1 text-sm"> {account.phoneNumber}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center h-36">
                                <div className="flex flex-col">
                                    <div className="flex flex-row py-2">
                                        <SlLocationPin className="m-2" />
                                        <span className="m-1 text-sm">{account.country}</span>
                                    </div>
                                    <div className="flex flex-row ">
                                        <CiMail className="m-2" />
                                        <span className="m-1 text-sm">{account.email}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="flex items-center justify-end h-12 lg:h-36">
                            <div className="flex items-center justify-center w-full pr-6 space-x-8 space-y-4 lg:flex-col">
                                <div className="hidden lg:block">
                                    <button
                                        className="text-xl text-blue-800 hover:text-2xl"
                                        onClick={() => dispatch(toggleProfileModal())}
                                    ><FiEdit /></button>
                                </div>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <>
                {isProfileModalOpen ? (
                    <Modal showModal={isProfileModalOpen} setShowModal={toggleProfileModal} title={title}>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={onSubmit}
                        >
                            {({ isSubmitting }) => (
                                <Form>
                                    <div className="flex flex-row p-3 space-x-3">
                                        <label className='w-1/6 mt-2' htmlFor="firstName">Fisrt Name:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                type="text"
                                                name="firstName"
                                                placeholder="John"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="firstName"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row p-3 space-x-3">
                                        <label className='w-1/6 mt-2' htmlFor="lastName">Last Name:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="lastName"
                                                placeholder="Smith"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="lastName"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row p-3 space-x-3">
                                        <label className='w-1/6 mt-2' htmlFor="country">Country:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="country"
                                                placeholder="Country"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="country"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>

                                    <div className="flex flex-row p-3 space-x-3">
                                        <label className='w-1/6 mt-2' htmlFor="phoneNumber">Phone:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                type="number"
                                                name="phoneNumber"
                                                placeholder="phoneNumber"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="phoneNumber"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center justify-between p-3 pt-4 mt-3">
                                        <button
                                            onClick={() => dispatch(toggleProfileModal())}
                                            className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                        >
                                            <span

                                                className="flex flex-row space-x-1"
                                            >
                                                <IoMdClose className="m-1" />
                                                <span>Cancel</span>
                                            </span>
                                        </button>
                                        <button
                                            type="submit"
                                            className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                        >
                                            {isSubmitting ? (
                                                <div className="flex flex-row px-2">
                                                    <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                    <span>processing</span>
                                                </div>
                                            ) : (
                                                <div className="flex flex-row space-x-1 hover:font-bold">
                                                    <TiTickOutline className="m-1" />
                                                    <span>Save</span>
                                                </div>
                                            )}
                                        </button>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </Modal>
                ) : null
                }
            </>

        </>
    );
};

export default TechProfile;

