// @ts-nocheck
import React, { useState } from "react";
import { IoMdAdd } from "react-icons/io";
import { TiTickOutline } from "react-icons/ti";
import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { FiEdit } from "react-icons/fi";
import Modal from "../../../../components/common/Modal";
import { useDispatch, useSelector } from "react-redux";
import { toggleAboutModal } from "../../../../features/layout/ModalSlice";
import {
    addAboutMe,
    updateAboutMe,
} from "../../../../features/profile/aboutSlice";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';

const Description = () => {
    const dispatch = useDispatch();
    const { isAboutModalOpen } = useSelector((store) => store.modal);
    const { about } = useSelector((store) => store.user.profiledata);
    const [showModal, setShowModal] = useState(false);

    const isNonEmptyString = (str) => {
        return typeof str === "string" && str.length > 0;
    }

    let title = isNonEmptyString(about) ? "Update Description" : "Add Description";
    const initialValues = {
        about: about,
    };

    //unable to patch on prod
    const onSubmit = ({ about }) => {
        const action = isNonEmptyString(about) ? updateAboutMe : addAboutMe;
        dispatch(action({ about }));
    };
    
    const validationSchema = Yup.object({
        about: Yup.string().required("Description is required"),
    });

    return (
        <>
            <ToastContainer />
            {isAboutModalOpen ? (
                <Modal
                    showModal={showModal}
                    setShowModal={toggleAboutModal}
                    title={title}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="flex flex-row w-full">
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            as="textarea"
                                            rows="6"
                                            name="about"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            placeholder="john doe"
                                        />
                                        <ErrorMessage
                                            name="about"
                                            component="div"
                                            className="text-sm italic text-red-600"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between pt-4 mt-3">
                                    <button
                                        onClick={() => dispatch(setShowModal())}
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <button
                                            onClick={() => dispatch(toggleAboutModal())}
                                            className="flex flex-row space-x-1 hover:font-bold"
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </button>
                                    </button>

                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>processing</span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}
            <div className="flex flex-row justify-between m-4 space-x-3">
                <div className="flex flex-row">
                    <h3 className="mt-4 text-2xl font-semibold text-black">About Me</h3>
                    <button
                        onClick={() => dispatch(toggleAboutModal())}
                        className="px-3 mt-4 text-2xl hover:text-3xl"
                        title="add certification"
                    >
                        <IoMdAdd />
                    </button>
                </div>

                <button
                    onClick={() => dispatch(toggleAboutModal())}
                    className="px-3 mt-4 text-2xl hover:text-3xl"
                    title="update about me"
                >
                    <FiEdit className="text-blue-700" />
                </button>
            </div>
            <p className="mb-4">
                {about !== undefined && about.length > 0 ? (
                    <p>{about}</p>
                ) : (
                    <div className="text-sm leading-5 text-center text-red-400">
                        Add New Description
                    </div>
                )}
            </p>
        </>
    );
};

export default Description;
