import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import { getUserTokenFromLocalStorage } from "../../services/localStorage";
import { toggleProjectModal, toggleProjectUpdate } from "../layout/ModalSlice";


const token = getUserTokenFromLocalStorage();

const initialState = {
  isLoading: false,
  tokendata: "",
  datatoken: getUserTokenFromLocalStorage,
  isEmailConfirmed: false,
  isResetPassword: false,
  userdata: [],
  projects: []
};



// Project
export const addUserProject = createAsyncThunk(
  "/profile/projects",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.post("/profile/projects", data);
      if (response.data && response.data.success) {
        dispatch(toggleProjectModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

//PATCH 
export const updateProject = createAsyncThunk(
  "/profile/projects:id",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.patch(`/profile/projects/${data.id}`, data);
      if (response.data && response.data.success) {
        dispatch(toggleProjectUpdate());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

//DELETE
export const deleteProject = createAsyncThunk(
  "/profile/projects:id",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.delete(`/profile/projects/${data.data.id}`);
      if (response.data && response.data.success) {
        //dispatch(toggleProjectModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);



const projectSlice = createSlice({
  name: "projectSlice",
  initialState,
  extraReducers: (builder) => {
    builder
      //POST
      .addCase(addUserProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addUserProject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
        window.location.reload();
      })
      .addCase(addUserProject.rejected, (state, action) => {
        state.isLoading = false;

        toast.error(action.payload)
      })
      //PATCH
      .addCase(updateProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProject.fulfilled, (state, action) => {
        state.isLoading = false;
        window.location.reload();
      })
      .addCase(updateProject.rejected, (state, action) => {
        state.isLoading = false;

        toast.error(action.payload)
      })
      //DELETE
      .addCase(deleteProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteProject.fulfilled, (state, action) => {
        state.isLoading = false;
        window.location.reload();
      })
      .addCase(deleteProject.rejected, (state, action) => {
        state.isLoading = false;

        toast.error(action.payload)
      })

  },
});

export default projectSlice.reducer;