// @ts-nocheck
import React, { useState } from 'react';


function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  function handleOnError(error, errorInfo) {
    // log the error to an error reporting service
    setHasError(true);
  }

  return hasError ? (
    // fallback UI or error message
    <h1>Something went wrong.</h1>
  ) : (
    <React.Fragment onError={handleOnError}>
      {children}
    </React.Fragment>
  );
}

export default ErrorBoundary;
