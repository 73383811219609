import { useState } from "react";
import moment from "moment";
import { FiEdit } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { HiOutlineDotsVertical } from "react-icons/hi";
import Modal from "../../common/Modal";
import { TiTickOutline } from "react-icons/ti";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { IoMdClose } from "react-icons/io";
import { FaSpinner } from 'react-icons/fa';
import {
    deleteEducation,
    updateEducation
} from "../../../features/profile/educationSlice";
import { ToastContainer } from "react-toastify";
import DeleteModal from "../../common/DeleteModal";
import { toggleEducationUpdate } from "../../../features/layout/ModalSlice";

const SingleEducation = ({ education }) => {

    const { isEducationUpdate } = useSelector((store) => store.modal);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [data, setData] = useState({});
    const dispatch = useDispatch();

    const { _id, institution, level, country, start_date, end_date } = data;
    const title = "Update Education";
    let id = _id;
    const initialValues = {
        institution: institution,
        level: level,
        country: country,
        start_date: start_date,
        end_date: end_date,
    };

    const handlePopOver = (id) => {
        setSelectedId(selectedId === id ? null : id);
        setIsDropdownVisible(id === selectedId ? !isDropdownVisible : true);
    };

    const handleEdit = (id) => {
        const edu = education.find((edu) => edu._id === id);
        if (edu !== undefined) {
            setData(edu);
        }
    };
    const handleDelete = (id) => {
        setIsSubmitting(!isSubmitting)
        const edu = education.find((edu) => edu._id === id);
        if (edu !== undefined) {

            dispatch(deleteEducation({ data: { id } }));
            setTimeout(() => {
                setIsDeleteModalOpen(!isDeleteModalOpen);
            }, 2000);
        }
    };
    const handleDeleteModal = (event) => {
        event.stopPropagation();
        setIsDeleteModalOpen(!isDeleteModalOpen);
    }

    const onSubmit = (values) => {

        //PATCH
        dispatch(
            updateEducation({
                data: {
                    institution: values.institution,
                    level: values.level,
                    country: values.country,
                    start_date: values.start_date,
                    end_date: values.end_date,
                },
                id: id,
            })
        );
    };

    if (!education || education.length === 0) {
        return <div className='text-sm leading-5 text-center text-red-400'>Add New Education</div>;
    }

    return (
        <>
            <ToastContainer />
            {education.slice(0, 3).map((item) => (
                <>
                    <div className='flex justify-between m-2 space-y-3 bg-gray-100 rounded-md shadow-sm' key={item.id}>
                        <div className='flex flex-row space-x-3'>
                            <img alt="" className='hidden lg:block' src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjd4v7Hm-xkJAiDgqgftQIUj3paIn2H1rLCw&usqp=CAU" style={{ width: '150px' }} />
                            <div className='flex flex-col justify-center'>
                                <h3 className='font-semibold text-black lg:text-xl'>{item.institution}</h3>
                                <span className='text-sm'>{item.country}</span>
                            </div>
                        </div>
                        <div className='flex justify-center'>
                            <div className='flex flex-col justify-center'>
                                <h3 className='font-semibold lg:text-xl'>{moment(item.start_date).format('MMMM YYYY')}  -  {moment(item.end_date).format('MMMM YYYY')}</h3>
                                <span className='text-end'>{item.country}</span>
                            </div>
                            {/* Actions */}
                            {selectedId === item._id && (
                                <div className="relative flex flex-row items-center justify-center gap-2">
                                    <div
                                        className={`z-10 ${isDropdownVisible ? "" : "hidden"
                                            } bg-gray-100 divide-y divide-gray-200 rounded-lg shadow w-20 absolute opt_up`}
                                    >
                                        <ul
                                            className="py-2 text-sm text-gray-700 dark:text-gray-200"
                                            aria-labelledby="dropdownMenuIconButton"
                                        >
                                            <li>
                                                <span
                                                    onClick={() => {
                                                        dispatch(toggleEducationUpdate(true));
                                                        handleEdit(item._id);
                                                    }}
                                                    className="block px-4 py-2 cursor-pointer hover:bg-gray-200"
                                                >
                                                    <FiEdit className="text-blue-800" />
                                                </span>
                                            </li>
                                            <li>
                                                <span
                                                    onClick={handleDeleteModal}
                                                    className="block px-4 py-2 cursor-pointer hover:bg-gray-200"
                                                >
                                                    <RiDeleteBin5Line className="text-red-600" />
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            )}
                            <button
                                onClick={() => handlePopOver(item._id)}
                                id="dropdownMenuIconButton"
                                data-dropdown-toggle="dropdownDots"
                                type="button"
                            >
                                <HiOutlineDotsVertical className="p-1 text-3xl" />
                            </button>
                        </div>
                    </div>
                    {/* DELETE */}
                    {isDeleteModalOpen ? (
                        <DeleteModal title="Work Experience">
                            <div className="flex items-center justify-between p-4">
                                <button
                                    className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                >
                                    <span
                                        onClick={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                                        className="flex flex-row space-x-1 font-semibold hover:text-red-700 hiver:font-bold "
                                    >
                                        <IoMdClose className="m-1" />
                                        <span>Cancel</span>
                                    </span>
                                </button>
                                <button
                                    type="submit"
                                    className="inline-flex items-center px-4 py-2 text-white bg-red-600 rounded-lg hover:bg-red-700"
                                >
                                    {isSubmitting ? (
                                        <div className="flex flex-row px-2">
                                            <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                            <span>Processing</span>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={() => handleDelete(item._id)}

                                            className="flex flex-row space-x-1 hover:font-bold">
                                            <TiTickOutline className="m-1" />
                                            <span>Delete</span>
                                        </button>
                                    )}
                                </button>
                            </div>
                        </DeleteModal>
                    ) : null}
                </>
            ))}
            {/* Update */}
            {isEducationUpdate ? (
                <Modal title={title} setShowModal={toggleEducationUpdate}>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        enableReinitialize
                    >
                        {({ onSubmit, isSubmitting }) => (
                            <Form>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className="w-1/6 mt-2 " htmlFor="institution">
                                        Name:{" "}
                                    </label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            type="text"
                                            name="institution"
                                            placeholder="Institution"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="institution"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className="w-1/6 mt-2" htmlFor="level">
                                        Level:
                                    </label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="level"
                                            placeholder="Winner"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="level"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className="w-1/6 mt-2" htmlFor="country">
                                        Country:
                                    </label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="country"
                                            placeholder="place of awarded"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="country"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>

                                <div className="flex flex-row w-full p-3 space-x-3">
                                    <label className="w-1/6 mt-2" htmlFor="start_date">
                                        Start Date:
                                    </label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="start_date"
                                            type="date"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="start_date"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full p-3 space-x-3">
                                    <label className="w-1/6 mt-2" htmlFor="end_date">
                                        End Date:
                                    </label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="end_date"
                                            type="date"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="end_date"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-3 pt-4 mt-3">
                                    <button
                                        onClick={() => dispatch(toggleEducationUpdate())}
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <span
                                            className="flex flex-row space-x-1 hover:font-bold"
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </span>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>processing</span>
                                            </div>
                                        ) : (

                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}
        </>
    )
}

export default SingleEducation