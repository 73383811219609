// @ts-nocheck
import { IoDownloadOutline } from "react-icons/io5";
import { HiOutlineDotsVertical } from "react-icons/hi";
import { useState } from "react";
import Select from 'react-select';
import { toast } from "react-toastify";
import customFetch from "../../../../Utils/hooks/axios";
import { useSelector } from "react-redux";

const options = [
    { value: 'cv', label: 'Curriculum Vitae' },
    // { value: 'coverletter', label: 'Cover Letter' },
    // { value: 'applicationletter', label: 'Application Letter' }
];

const AttachedFiles = () => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedFile, setSelectedFile] = useState(null);
    const { cv } = useSelector((store)=>store.user?.profiledata)

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault(); // Prevent form submission

        if (selectedFile) {
            const formData = new FormData();
            formData.append('cv', selectedFile);

            customFetch
                .post(`/profile/cv`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(response => {
                    toast.success(response.data.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                })
                .catch(error => {
                    const errorMessage =
                        error.response.data.error || error.response.data.message || error.message;
                    toast.error(errorMessage, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        }
    };

    return (
        <>
            <div className="m-2 p-2">
                <div className='flex flex-row space-x-3 m-4'>
                    <h3 className='text-black text-xl font-semibold mt-4'>Attached Files</h3>
                </div>
                <div className='flex justify-between py-2'>
                    <div className='flex flex-row space-x-3'>
                        <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQjd4v7Hm-xkJAiDgqgftQIUj3paIn2H1rLCw&usqp=CAU" style={{ height: '50px' }} alt="file" />
                        <div className='flex flex-col justify-center'>
                            <h6 className='text-black text-sm'>Curiculum Vitae</h6>
                            <span className='text-sm'>100kb</span>
                        </div>
                    </div>
                    <div className='flex justify-center'>
                        <div className='flex flex-row justify-center space-x-3'>
                            <button type='submit'>
                                <a href={cv} download><IoDownloadOutline /></a>
                            </button>
                            <button type='submit'>
                                <HiOutlineDotsVertical />
                            </button>
                        </div>
                    </div>
                </div>

                <form onSubmit={handleSubmit} className="space-y-3">
                    <div>
                        <span className="text-sm font-semibold">Select a file type:</span>
                        <Select
                            value={selectedOption}
                            onChange={handleSelectChange}
                            options={options}
                        />
                    </div>
                    <div className="relative bg-blue-900 hover:bg-blue-800 mt-2 rounded flex flex-row px-2">
                        <input
                            type="file"
                            name="avatar"
                            onChange={handleFileChange}
                            className="opacity-0 absolute inset-0 z-50"
                        />
                        <label
                            htmlFor="file"
                            className="text-white text-sm py-2 px-4 rounded w-full text-center"
                        >
                            Attach File
                        </label>
                    </div>
                    <div className='flex justify-end'>
                        {selectedFile && (
                            <button
                                type="submit"
                                className="bg-blue-800 hover:bg-blue-700 text-white text-sm py-1 px-4 rounded"
                            >
                                Submit
                            </button>
                        )}
                    </div>
                </form>
            </div>
        </>
    );
};

export default AttachedFiles;
