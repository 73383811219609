// @ts-nocheck
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import { getTalentRequests } from '../../features/talentRequest/talentRequestSlice';
import DebugData from '../common/DebugData';

const TalentRequests = () => {
    const [filterText, setFilterText] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();
   const {requests} = useSelector((store) => store.talentRequest) || [];

    useEffect(() => {
        dispatch(getTalentRequests());
    }, [dispatch]);

    const handleViewMore = (id) => {
        navigate(`/b710ef70ab384c3fba0809c3f3ee0b6b/${id}`);
    };

    const handleFilter = (e) => {
        setFilterText(e.target.value);
    };

    const columns = [
        {
            name: "Role",
            selector: (row) => row.request.role,
            sortable: true,
            filter: true,
            cell: (row) => (
                <span className="font-semibold text-green-800 text-md">
                    {row.request.role}
                </span>
            ),
        },
        {
            name: "Stack",
            selector: (row) => row.request.technology_stack,
            sortable: true,
            filter: true,
            cell: (row) => <span className="text-md">{row.request.technology_stack}</span>,
        },
        {
            name: "Country",
            selector: (row) => row.request.coutry,
            sortable: true,
            filter: true,
            cell: (row) => <span className="text-md">{row.request.coutry}</span>,
        },
        {
            name: "Positions",
            selector: (row) => row.request.number_of_positions,
            sortable: true,
            filter: true,
        },
        {
            name: "Availability",
            selector: (row) => row.request.availability,
            sortable: true,
            filter: true,
            cell: (row) => <span className="text-md">{row.request.availability}</span>,
        },
        {
            name: "Deadline",
            selector: (row) => row.request.application_deadline,
            sortable: true,
            filter: true,
            cell: (row) => <span className="text-md">{row.request.application_deadline}</span>,
        },
        // {
        //     name: "Progress",
        //     cell: (row) => (
        //         <div className="flex w-full bg-yellow-200 rounded-full">
        //             <div
        //                 className="text-sm leading-none text-center text-blue-900 bg-yellow-400 rounded-full"
        //                 style={{ width: "20%" }}
        //             >
        //                 {row.progress}%
        //             </div>
        //         </div>
        //     ),
        // },
        {
            name: "Actions",
            cell: (row) => (
                <button
                    onClick={() => handleViewMore(row._id)}
                    className="w-20 px-4 py-1 font-semibold text-blue-800 rounded outline outline-1 hover:bg-blue-900 hover:text-white"
                >
                    View
                </button>
            ),
        },
    ];

    const filteredRequests = requests.filter((request) =>
        Object.values(request).some(
            (value) =>
                typeof value === "string" &&
                value.toLowerCase().includes(filterText.toLowerCase())
        )
    );
    const customStyles = {
        headRow: {
            style: {
                background: "linear-gradient(90deg, #2b4dd5, gold)",
                fontWeight: "bold",
                color: "white",
                marginTop: "0.75rem",
                textTransform: "uppercase",
            },
        },
        tableWrapper: {
            style: {
                padding: "0rem",
            },
        },
        pagination: {
            style: {
                marginTop: "0.1rem",
            },
        },
    };
    if (!Array.isArray(requests)) {
        return <p>No client requests available.</p>;
    }

    return (
        <>
            <ToastContainer />
            <div className="bg-gray-200 h-[100vh] rounded overflow-y-auto mt-20">
                <div className="p-3">
            {/* <DebugData data={requests} /> */}
                    <DataTable
                        columns={columns}
                        data={filteredRequests}
                        pagination
                        customStyles={customStyles}
                        highlightOnHover
                        subHeader
                        subHeaderComponent={
                            <>
                                <div className="flex flex-col w-full m-3 md:flex-row md:justify-between">
                                <h3 class="font-bold text-2xl text-blue-900">My Requests</h3>
                                    <input
                                        type="search"
                                        placeholder="Search client request"
                                        value={filterText}
                                        onChange={handleFilter}
                                        className="w-full px-4 py-2 text-sm text-center border border-gray-300 rounded-lg hover:border-blue-700 focus:outline-none md:w-48 lg:w-56"
                                    />
                                </div>
                            </>
                        }
                    />
                </div>
            </div>
        </>
    );
};

export default TalentRequests;


