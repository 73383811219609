import { createSlice } from '@reduxjs/toolkit';



const initialState = {
    isProfileOpen: false,
}


const navSlice = createSlice({
    name: 'nav',
    initialState,
    reducers: {
        toggleProfile: (state) => {
            state.isProfileOpen = !state.isProfileOpen;

        },
    },
});
export const { toggleProfile } = navSlice.actions
export default navSlice.reducer