// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import axios from "axios";
import { getUserTokenFromLocalStorage } from "../../services/localStorage";

const initialState = {
    isLoading: false,
    requests: [],
    request: [],
};

export const getTalentRequests = createAsyncThunk(
    "/profile/client_requests",
    async (_, { rejectWithValue }) => {
        try {

            const response = await customFetch.get("/profile/client_requests");
            if (response.data && response.data.success) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            if (error) {
                const errorMessage = error.response?.data?.error || error.message;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const getTalentNDA = createAsyncThunk(
    "/shared/talent_matching/:id/client_nda",
    async (id, { rejectWithValue }) => {
        try {
            const token = getUserTokenFromLocalStorage();
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.get(
                `https://somestuff.herokuapp.com/api/v2/shared/talent_matching/${id}/talent_nda`,
                config
            );

            if (response.data && response.data.success) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            // if (error) {
            //     const errorMessage = error.response?.data?.error || error.message;
            //     toast.error(errorMessage, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            // }
            return rejectWithValue(error.response.data.message);
        }
    }
);
export const getOfferContract = createAsyncThunk(
    "/shared/talent_matching/:id/talent_contract",
    async (id, { rejectWithValue }) => {
        try {
            const token = getUserTokenFromLocalStorage();
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
            const response = await axios.get(
                `https://somestuff.herokuapp.com/api/v2/shared/talent_matching/${id}/talent_contract`,
                config
            );

            if (response.data && response.data.success) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            // if (error) {
            //     const errorMessage = error.response?.data?.error || error.message;
            //     toast.error(errorMessage, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            // }
            return rejectWithValue(error.response.data.message);
        }
    }
);


export const getTalentRequest = createAsyncThunk(
    "/profile/client_request",
    async (id, { dispatch, rejectWithValue }) => {
        try {
            const response = await customFetch.get(`/profile/client_requests/${id}`);
            if (response.data && response.data.success) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            // if (error) {
            //     const errorMessage = error.response?.data?.error || error.message;
            //     toast.error(errorMessage, {
            //         position: toast.POSITION.TOP_RIGHT
            //     });
            // }
            return rejectWithValue(error.response.data.message);
        }
    }
);

const talentRequestSlice = createSlice({
    name: "TalentRequests",
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getTalentRequests.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTalentRequests.fulfilled, (state, action) => {
                state.isLoading = false;
                state.requests = action.payload.data;
            })
            .addCase(getTalentRequests.rejected, (state, action) => {
                state.isLoading = false;
                toast.error(action.payload);
            })
            .addCase(getTalentRequest.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTalentRequest.fulfilled, (state, action) => {
                state.isLoading = false;
                state.request = action.payload.data;
            })
            .addCase(getTalentRequest.rejected, (state, action) => {
                state.isLoading = false;
                toast.error(action.payload);
            })
            .addCase(getTalentNDA.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getTalentNDA.fulfilled, (state, action) => {
                state.isLoading = false;
                state.nda = action.payload.data;
            })
            .addCase(getTalentNDA.rejected, (state, action) => {
                state.isLoading = false;
                toast.error(action.payload);
            })
    },
});

export default talentRequestSlice.reducer;
