import { createSlice } from '@reduxjs/toolkit';

let initialState = {
    isAboutModalOpen: false,
    isProfileModalOpen: false,
    isProjectModalOpen: false,
    isSkillsModalOpen: false,
    isExperienceModalOpen: false,
    isEducationModalOpen: false,
    isCertificationModalOpen: false,
    isAwardsModalOpen: false,
    isUpdateModalOpen: false,
    isDeleteModalOpen: false,
    isEducationUpdate: false,
    isProjectUpdate: false,
    isCertUpdate: false,
    isAwardUpdate: false,
    isSocialsModalOpen: false,
    isEdit: false,
}


const modalSlice = createSlice({
    name: 'Modals',
    initialState,
    reducers: {
        toggleAboutModal: (state) => {
            state.isAboutModalOpen = !state.isAboutModalOpen;
        },
      
        toggleProfileModal: (state) => {
            state.isProfileModalOpen = !state.isProfileModalOpen;
        },
        toggleProjectModal: (state) => {
            state.isProjectModalOpen = !state.isProjectModalOpen;
        },
        toggleSkillsModal: (state) => {
            state.isSkillsModalOpen = !state.isSkillsModalOpen;
        },
        toggleExperienceModal: (state) => {
            state.isExperienceModalOpen = !state.isExperienceModalOpen;
        },
        toggleEducationModal: (state) => {
            state.isEducationModalOpen = !state.isEducationModalOpen;
        },
        toggleCertificationModal: (state) => {
            state.isCertificationModalOpen = !state.isCertificationModalOpen;
        },
        toggleAwardsModal: (state) => {
            state.isAwardsModalOpen = !state.isAwardsModalOpen;
        },
        toggleUpdateModal: (state) => {
            state.isUpdateModalOpen = !state.isUpdateModalOpen;
        },
        toggleDeleteModal: (state) => {
            state.isDeleteModalOpen = !state.isDeleteModalOpen;
        },
        toggleEducationUpdate: (state) => {
            state.isEducationUpdate = !state.isEducationUpdate;
        },
        toggleProjectUpdate: (state) => {
            state.isProjectUpdate = !state.isProjectUpdate;
        },
        toggleCertUpdate: (state) => {
            state.isCertUpdate = !state.isCertUpdate;
        },
        toggleAwardUpdate: (state) => {
            state.isAwardUpdate = !state.isAwardUpdate;
        },
        toggleSocialUpdate: (state) => {
            state.isEdit = !state.isEdit;
        },
        toggleSocialModal: (state) => {
            state.isSocialsModalOpen = !state.isSocialsModalOpen;
        },
    },
});

export const { toggleAboutModal, toggleProfileModal, toggleProjectModal, toggleSkillsModal, toggleExperienceModal,
    toggleEducationModal, toggleCertificationModal, toggleAwardsModal, toggleUpdateModal, toggleDeleteModal,
    toggleEducationUpdate, toggleProjectUpdate, toggleCertUpdate, toggleAwardUpdate,toggleSocialModal,toggleSocialUpdate } = modalSlice.actions
export default modalSlice.reducer