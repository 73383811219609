import React, { useState } from 'react'
import { IoMdAdd } from "react-icons/io"
import { FaSpinner } from 'react-icons/fa';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup'
import { TiTickOutline } from "react-icons/ti";
import { IoMdClose } from "react-icons/io";
import { toggleCertificationModal, toggleEducationModal } from '../../../../features/layout/ModalSlice';
import Modal from '../../../../components/common/Modal';
import { addCertification } from '../../../../features/profile/certAwardsSlice';
import ErrorBoundary from '../../../../constants/ErrorBoundary';
import Certification from '../../../../components/profile/certificationawards/Certification';
import { ToastContainer } from "react-toastify";

const Certifications = () => {


    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const { isCertificationModalOpen } = useSelector((store) => store.modal);
    const { certifications } = useSelector((store) => store.user.profiledata);

    const title = "Certifications"
    const initialValues = {
        name: "",
        institution: "",
        expiry: "",
        date: "",
    };

    const onSubmit = (values) => {

        dispatch(addCertification({ name: values.name, institution: values.institution, date: values.date, country: values.expiry }))
    }
    const validationSchema = Yup.object({
        name: Yup.string().required("Certification Name is required"),
        institution: Yup.string().required("Institution Name is required"),
        expiry: Yup.string().required("Expiry is required"),
        date: Yup.mixed().required("Issued Date is required"),
    });

    return (
        <>
            <ToastContainer />
            {isCertificationModalOpen ? (
                <Modal
                    showModal={showModal}
                    setShowModal={toggleCertificationModal}
                    title={title}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="name">Name:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            type="text"
                                            name="name"
                                            placeholder="Certification Name"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="name"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="institution">Institution:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="institution"
                                            placeholder="Winner"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="institution"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>

                                <div className='flex flex-row space-x-3'>
                                    <div className="flex flex-row w-full p-3 space-x-3">
                                        <label className='w-20 mt-2' htmlFor="date">Issued:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="date"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="date"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="expiry">Expiry:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="expiry"
                                            placeholder="expiry"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="expiry"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-3 pt-4 mt-3">
                                    <button
                                        onClick={() => dispatch(setShowModal())}
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <button
                                            onClick={() => dispatch(toggleCertificationModal())}
                                            className="flex flex-row space-x-1"
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </button>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>processing</span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}

            <div className='flex flex-row m-4'>
                <h3 className='text-2xl font-semibold text-black'>Certifications</h3>
                <button
                    onClick={() => dispatch(toggleCertificationModal())}
                    className="px-3 text-2xl hover:text-3xl" title='add certification'
                ><IoMdAdd /></button>
            </div>
            {/* item */}
            {
                <ErrorBoundary>
                    <Certification certifications={certifications} />
                </ErrorBoundary>
            }

        </>
    )
}

export default Certifications