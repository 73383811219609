// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import { toggleAboutModal } from "../layout/ModalSlice";


const initialState = {
    isLoading: false,
};

export const addHiringPreference = createAsyncThunk(
    "profile/hiring/preference",
    async (data, { rejectWithValue }) => {
        try {
            const response = await customFetch.post("profile/hiring/preference", data);

            if (response.data && response.data.success) {
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            if (error) {
                const errorMessage = error.response?.data?.error || error.message;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return rejectWithValue(error.response.data.message);
        }
    }
);

export const updateHiringPreference = createAsyncThunk(
    "profile/hiring/preference/patch",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await customFetch.patch("profile/hiring/preference", data);

            if (response.data && response.data.success) {
                dispatch(toggleAboutModal());
                toast.success(response.data.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            if (error) {
                const errorMessage = error.response?.data?.error || error.message;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return rejectWithValue(error.response.data.message);
        }
    }
);



const hiringSlice = createSlice({
    name: "hiring preference",
    initialState,
    reducers: {
        updateProfile(state, action) {

            state.data = action.payload;
        },
        // other reducers
    },
    extraReducers: (builder) => {
        builder
            .addCase(addHiringPreference.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addHiringPreference.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(addHiringPreference.rejected, (state, action) => {
                state.isLoading = false;
                toast.error(action.payload);
            })
            .addCase(updateHiringPreference.pending, (state) => {
                state.isLoading = false;
            })
            .addCase(updateHiringPreference.fulfilled, (state, action) => {
                state.isLoading = false;
            })
            .addCase(updateHiringPreference.rejected, (state, action) => {
                state.isLoading = false;
            })
    },
});

export const { updateProfile } = hiringSlice.actions;
export default hiringSlice.reducer;
