// @ts-nocheck
import { useEffect } from "react";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getUserData } from "../../../features/auth/userSlice";
import React from "react";

const ProgressBar = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserData())
  }, [dispatch]);

  let percentage
  const { socials, hiringPreference, cv, experience, education, projects, certifications, awards, about, account } = useSelector((store) => store.user.profiledata || {});

  if (!socials && !hiringPreference && !cv && !experience && !education && !projects && !certifications && !awards && !about && !account) {
  } else {
    const numerator = [socials, hiringPreference, experience, education, projects, certifications, awards, about].filter(item => item?.length > 0).length;
    const denominator = 7;
    percentage = Math.round(((numerator / denominator) * 100));
    sessionStorage.setItem('progress', percentage);
  }

  const styles = {
    path: {
      // Customize the progress path color
      stroke: `rgba(255,205,0, ${percentage / 100})`,
      // Customize the progress trail color
      strokeLinecap: "butt",
      trailColor: "#a11313",
      trailWidth: 9,
    },
    // Customize the text color
    text: {
      fill: "#f88",
      fontSize: "16px",
    },
  };

  return (
    <div className="flex flex-col w-7/12">
      <div>
        <CircularProgressbarWithChildren value={percentage} styles={styles}>
          <div>
            <h3 className="font-bold text-3xl">{percentage}% Complete</h3>
            {/* <h4 className="font-semibold text-center text-xl">
              On step {currentStep}
            </h4> */}
          </div>
        </CircularProgressbarWithChildren>
      </div>
      <div className="py-6">
        <Link to="/2tfSroM9CkWsP2oCLkmQMA">
          <button className="bg-blue-900 hover:bg-blue-800 text-white py-3 px-4 rounded w-full transform transition-all duration-500 ease-in-out hover:scale-105">
            Continue to the next step
          </button>
        </Link>
      </div>
    </div>
  );
};

export default ProgressBar;
