import React from 'react'
import { Field } from "formik";
import Select from 'react-select'

const MultiSelectField = ({ name,defaultValue, options, ...rest }) => {
    return (
        <Field name={name}>
            {({ field, form }) => (
                <Select
                    {...field}
                    {...rest}
                    isMulti
                    options={options}
                    defaultValue={defaultValue}
                    onChange={(value) => {
                        form.setFieldValue(name, value);
                    }}
                    onBlur={() => {
                        form.setFieldTouched(name, true);
                    }}
                />
            )}
        </Field>
    )
}

export default MultiSelectField