
import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isSideBarOpen: true,
}

const sideBarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        toggleMenu: (state) => {
            state.isSideBarOpen = !state.isSideBarOpen;
        },
        resetMenu: (state) => {
            state.isSideBarOpen = true;
        }
    }
});
export const { toggleMenu, resetMenu } = sideBarSlice.actions;
export default sideBarSlice.reducer