import React from 'react'
import ReactDom from 'react-dom'
import { useDispatch, useSelector } from "react-redux";

const Modal = ({ showModal, title, setShowModal, children, data }) => {

    const dispatch = useDispatch();
    const { isAboutModalOpen } = useSelector((store) => store.modal.isAboutModalOpen);

    return ReactDom.createPortal(
        <>
            {!isAboutModalOpen ?
                <div className='h-screen bg-gray-500 bg-opacity-75 transition-opacity md:w-[100%] w-[100%] mx-auto justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none' style={{
      zIndex: 1000,
      backgroundColor: 'rgba(0, 0, 0, .7)',
    }}  >
                    <div className="relative w-auto max-w-3xl mx-auto">
                        {/*content*/}
                        <div className="relative flex flex-col w-full bg-white border-0 rounded-lg shadow-lg outline-none focus:outline-none">
                            {/*header*/}
                            <div className="flex justify-between p-3 space-y-3 bg-blue-900 border-b border-solid rounded-t-lg border-slate-200 -t">
                                <div className='flex items-center justify-center'>
                                    <h3 className="space-y-3 text-2xl text-white">
                                        {title}
                                    </h3>
                                </div>
                                <div className='flex items-center justify-center'>
                                    <button
                                        className='pr-5 space-y-3 text-2xl text-white'
                                        onClick={() => dispatch(setShowModal())}
                                    >
                                        X
                                    </button>
                                </div>
                            </div>
                            {/*body*/}
                            <div className="relative flex-auto w-[48rem] p-6">
                                {children}
                            </div>
                            {/*footer*/}
                        </div>
                    </div>
                </div>
                : null}
        </>,
    document.getElementById('portal')
    )
}

export default Modal