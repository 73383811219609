// @ts-nocheck
import React, { useState } from 'react'
import { IoMdAdd } from "react-icons/io"
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from 'yup'
import { TiTickOutline } from "react-icons/ti";
import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import { toggleExperienceModal } from '../../../../features/layout/ModalSlice';
import Modal from '../../../../components/common/Modal';
import { addWorkExperience } from '../../../../features/profile/experienceSlice';
import Experience from '../../../../components/profile/workExperience/Experience';
import ErrorBoundary from '../../../../constants/ErrorBoundary';
import { ToastContainer } from "react-toastify";
import { useNavigate } from 'react-router-dom';

const MyExperience = () => {

    const dispatch = useDispatch();
   
    const { isExperienceModalOpen } = useSelector((store) => store.modal);
    const { experience } = useSelector((store) => store.user.profiledata);

    const title = "Add Work Experience"
    const initialValues = {
        company_name: "",
        country: "",
        position: "",
        start_date: "",
        end_date: ""
    };

    const onSubmit = (values) => {
        const company_name = values.company_name;
        const position = values.position;
        const country = values.country;
        const start_date = values.start_date;
        const end_date = values.end_date;
        dispatch(addWorkExperience({ company_name, position, country, start_date, end_date }))
        
    }
    const validationSchema = Yup.object({
        company_name: Yup.string().required("Company Name is required"),
        country: Yup.string().required("Country Name is required"),
        position: Yup.string().required("JobTitle Name is required"),
        end_date: Yup.mixed().required("From Date is required"),
        start_date: Yup.mixed().required("start_date is required"),
    });

    return (
        <>
            <ToastContainer />
            {isExperienceModalOpen ? (
                <Modal
                    title={title}
                    setShowModal={toggleExperienceModal}
                >
                    <Formik
                        initialValues={initialValues}
                        onSubmit={onSubmit}
                        validationSchema={validationSchema}
                    >
                        {({ isSubmitting }) => (
                            <Form>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="company_name">Company:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            type="text"
                                            name="company_name"
                                            placeholder="Company Name"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="company_name"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>

                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="country">Country:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="country"
                                            placeholder="Country"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="country"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row p-3 space-x-3">
                                    <label className='w-20 mt-2' htmlFor="position">Job Title:</label>
                                    <div className="flex flex-col w-full space-y-3">
                                        <Field
                                            name="position"
                                            placeholder="Job Title"
                                            className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                        />
                                        <ErrorMessage
                                            name="position"
                                            component="div"
                                            className="text-sm italic text-red-700"
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-row space-x-3'>
                                    <div className="flex flex-row w-1/2 p-3 space-x-3">
                                        <label className='w-20 mt-2' htmlFor="start_date">From:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="start_date"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="start_date"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-row w-1/2 p-3 space-x-3">
                                        <label className='mt-2' htmlFor="end_date">To:</label>
                                        <div className="flex flex-col w-full space-y-3">
                                            <Field
                                                name="end_date"
                                                type="date"
                                                className="w-full px-4 py-2 leading-tight text-gray-700 border-2 border-gray-200 rounded-lg appearance-none  focus:outline-none focus:bg-white focus:border-gray-300"
                                            />
                                            <ErrorMessage
                                                name="end_date"
                                                component="div"
                                                className="text-sm italic text-red-700"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center justify-between p-3 pt-4 mt-3">
                                    <button
                                        className="px-6 py-2 mb-1 mr-1 text-sm transition-all duration-150 ease-linear outline-none text-black-500 background-transparent focus:outline-none"
                                    >
                                        <span
                                            onClick={() => dispatch(toggleExperienceModal())}
                                            className="flex flex-row space-x-1"
                                        >
                                            <IoMdClose className="m-1" />
                                            <span>Cancel</span>
                                        </span>
                                    </button>
                                    <button
                                        type="submit"
                                        className="inline-flex items-center px-4 py-2 text-white bg-blue-800 rounded-lg hover:bg-blue-800"
                                    >
                                        {isSubmitting ? (
                                            <div className="flex flex-row px-2">
                                                <FaSpinner className="w-5 h-5 mr-3 text-white animate-spin" />
                                                <span>Processing</span>
                                            </div>
                                        ) : (
                                            <div className="flex flex-row space-x-1 hover:font-bold">
                                                <TiTickOutline className="m-1" />
                                                <span>Save</span>
                                            </div>
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </Modal>
            ) : null}

            <div className='flex flex-row m-4 space-x-3'>
                <h3 className='mt-4 text-2xl font-semibold text-black'>Work Experience</h3>
                <button
                    onClick={() => dispatch(toggleExperienceModal())}
                    className="px-3 mt-4 text-2xl hover:text-3xl" title='add experience'
                ><IoMdAdd />
                </button>

            </div>
            {
                <ErrorBoundary>
                    <Experience experience={experience} />
                </ErrorBoundary>
            }
        </>
    )
}

export default MyExperience