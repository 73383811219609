// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import { toggleAwardsModal, toggleAwardUpdate, toggleCertificationModal, toggleCertUpdate } from "../layout/ModalSlice";

const initialState = {
  isLoading: false,
  certifications: [],
  awards: []
};

//POST certification
export const addCertification = createAsyncThunk(
  "profile/certifications/awards",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.post("/profile/certification", data);
      if (response.data && response.data.success) {
        dispatch(toggleCertificationModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

//PATCH certification
export const updateCertification = createAsyncThunk(
  "/profile/certification:id",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.patch(`/profile/certification/${data.id}`, data);
      if (response.data && response.data.success) {
        dispatch(toggleCertUpdate());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;

    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);
//DELETE certification 
export const deleteCertication = createAsyncThunk(
  "/profile/certification:id",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.delete(`/profile/certification/${data.data.id}`);
      if (response.data && response.data.success) {
        //dispatch(toggleCertificationModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

//POST award
export const addAward = createAsyncThunk(
  "profile/awards",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.post("/profile/awards", data);
      if (response.data && response.data.success) {
        dispatch(toggleAwardsModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
      return rejectWithValue(error.response?.data?.message);
    }
  }
);


//PATCH award
export const updateAward = createAsyncThunk(
  "/profile/awards:id",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.patch(`/profile/awards/${data.id}`, data);
      if (response.data && response.data.success) {
        dispatch(toggleAwardUpdate());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

//DELETE award 
export const deleteAward = createAsyncThunk(
  "/profile/awards:id",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.delete(`/profile/awards/${data.data.id}`);
      if (response.data && response.data.success) {
        // dispatch(toggleAwardsModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.BOTTOM_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);
const userSlice = createSlice({
  name: "Certification/awards",
  initialState,
  extraReducers: (builder) => {
    builder
      //POST certification
      .addCase(addCertification.pending, (state) => {

        state.isLoading = true;
      })
      .addCase(addCertification.fulfilled, (state, action) => {

        state.isLoading = false;
        state.profiledata = action.payload;
        toast.success(`Added Successfull`);
      })
      .addCase(addCertification.rejected, (state, action) => {

        state.isLoading = false;
        toast.error(action.payload);
      })

      //PATCH Certification
      .addCase(updateCertification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateCertification.fulfilled, (state, action) => {
        state.isLoading = false;
        state.projects = action.payload;
        window.location.reload();
      })
      .addCase(updateCertification.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload)
      })
      //DELETE AWARD
      .addCase(deleteCertication.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCertication.fulfilled, (state, action) => {

        state.isLoading = false;

        window.location.reload();
      })
      .addCase(deleteCertication.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload)
      })

      //POST AWARD
      .addCase(addAward.pending, (state) => {

        state.isLoading = true;
      })
      .addCase(addAward.fulfilled, (state, action) => {

        state.isLoading = false;
        state.profiledata = action.payload;
        toast.success(`Added Successfull`);
      })
      .addCase(addAward.rejected, (state, action) => {

        state.isLoading = false;
        toast.error(action.payload);
      })
      //PATCH AWARD
      .addCase(updateAward.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAward.fulfilled, (state, action) => {
        state.isLoading = false;
        window.location.reload();
      })
      .addCase(updateAward.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload)
      })
      //DELETE AWARD
      .addCase(deleteAward.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAward.fulfilled, (state, action) => {
        state.isLoading = false;

        window.location.reload();
      })
      .addCase(deleteAward.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload)
      })


  },
});

export default userSlice.reducer;
