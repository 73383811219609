// @ts-nocheck
import customFetch from "../../Utils/hooks/axios";
import { logoutUser } from "../auth/userSlice";
import {
    addTokenToLocalStorage
} from "../../services/localStorage";
import { toast } from "react-toastify";

export const registerUserThunk = async (url, data, thunkAPI) => {
    try {

        const resp = await customFetch.post(url, data);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }
};

export const loginUserThunk = async (url, currentUser, thunkAPI) => {
    try {

        const resp = await customFetch.post(url, currentUser, thunkAPI);
        if (resp.data.success) {
            addTokenToLocalStorage(resp.data.token);
        } else {
            return resp.data.error;
        }

        return resp.data;
    } catch (error) {
        toast.error(error.message);
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }
};

export const updateUserThunk = async (url, currentUser, thunkAPI) => {
    try {

        const resp = await customFetch.patch(url, currentUser, {
            headers: {
                authorization: `Bearer ${thunkAPI.getState().currentUser.token}`
            }
        })
        return resp.data;
    } catch (error) {
        if (error.response.status === 401) {
            thunkAPI.dispatch(logoutUser());
            return thunkAPI.rejectWithValue('Unauthorized! Logging out...')
        }
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }
}

export const logoutUserThunk = async (url, currentUser, thunkAPI) => {
    try {
        const resp = await customFetch.patch(url, currentUser, {
            headers: {
                authorization: `Bearer ${thunkAPI.getState().currentUser.token}`
            }
        })
        return resp.data;
    } catch (error) {

    }
}

export const getUserDataThunk = async (url, thunkAPI) => {
    try {

        const response = await customFetch.get(url, {
            withCredentials: true,
        });
        return response.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }
}

export const addUserProjectThunk = async (url, currentUser, thunkAPI) => {
    try {
        const resp = await customFetch.post(url, currentUser);
        return resp.data;
    } catch (error) {
        return thunkAPI.rejectWithValue(error.response.data.msg);
    }
};