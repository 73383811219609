// @ts-nocheck
import { useEffect, useState } from "react";
import ProgressItem from "./ProgressItem";
import { useDispatch, useSelector } from "react-redux";
import DebugData from '../../../components/common/DebugData';
import moment from "moment";
import { getUserData } from "../../../features/auth/userSlice";

const STEPS = [
  {
    title: "Create Account",
    status: "complete",
    date: "5th jun 2023",
  },
  {
    title: "Curriculum Vitae",
    status: "complete",
    date: "5th jun 2023",
  },
  {
    title: "About Me",
    status: "complete",
    date: "5th jun 2023",
  },
  {
    title: "Social Media",
    status: "pending",
    date: null,
  },
  {
    title: "Work Experience",
    status: "pending",
    date: null,
  },
  {
    title: "Education",
    status: "pending",
    date: null,
  },
  {
    title: "Project",
    status: "pending",
    date: null,
  },
  {
    title: "Certification",
    status: "pending",
    date: null,
  },
  {
    title: "Awards",
    status: "pending",
    date: null,
  },
];

const Stepper = () => {

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getUserData());
  }, [dispatch]);

  const [currentStep, setCurrentStep] = useState(0);
  const { experience } = useSelector((store) => store.user.profiledata);
  const { projects } = useSelector((store) => store.user.profiledata);
  const { socials } = useSelector((store) => store.user.profiledata);
  const { education } = useSelector((store) => store.user.profiledata);
  const { certifications } = useSelector((store) => store.user.profiledata);
  const { awards } = useSelector((store) => store.user.profiledata);
  const { createdAt } = useSelector((store) => store.user.profiledata);
  const { account } = useSelector((store) => store.user.profiledata);
  const experienceDate = experience?.[0]?.createdAt? moment(experience[0].createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;
  const projectDate = projects?.[0]?.createdAt ? moment(projects[0].createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;
  const socialDate = socials?.[0]?.createdAt ? moment(socials[0].createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;
  const educationDate = education?.[0]?.createdAt ? moment(education[0].createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;
  const certificationsDate = certifications?.[0]?.createdAt ? moment(certifications[0].createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;
  const awardsDate = awards?.[0]?.createdAt ? moment(awards[0].createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;
  const accountDate = account?.createdAt ? moment(account?.createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;
  const sharedDate = createdAt ? moment(createdAt).format("MMMM Do YYYY, h:mm:ss a") : null;

  //Override
  STEPS[0].date = accountDate ? "Completed at: " + accountDate : "Pending";
  STEPS[1].date = sharedDate ? "Completed at: " + sharedDate : "Pending";
  STEPS[2].date = sharedDate ? "Completed at: " + sharedDate : "Pending";
  STEPS[3].date = socialDate ? "Completed at: " + socialDate : "Pending";
  STEPS[4].date = experienceDate ? "Completed at: " + experienceDate : "Pending";
  STEPS[5].date = educationDate ? "Completed at: " + educationDate : "Pending";
  STEPS[6].date = projectDate ? "Completed at: " + projectDate : "Pending";
  STEPS[7].date = certificationsDate ? "Completed at: " + certificationsDate : "Pending";
  STEPS[8].date = awardsDate ? "Completed at: " + awardsDate : "Pending";

  return (
    <>
      <div className="flex flex-col space-y-3 w-full">
        <h1 className="font-bold text-black space-x-3 space-y-4 mb-4 text-3xl">
          Your Progress
        </h1>
        <ol className="relative text-gray-500 border-l-2 border-dashed border-yellow-500 ">
          {/* <DebugData data={awardsDate} /> */}
          {STEPS.map((step, index) => (
            <ProgressItem
              key={index}
              title={step.title}
              completed={step.status}
              date={step.date}
              isActive={index === currentStep}
            />
          ))}
        </ol>
      </div>
    </>
  );
};

export default Stepper;
