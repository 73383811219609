import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import { getUserTokenFromLocalStorage } from "../../services/localStorage";
import { toggleEducationModal, toggleEducationUpdate } from "../layout/ModalSlice";



const initialState = {
    isLoading: false,
    tokendata: "",
    datatoken: getUserTokenFromLocalStorage,
    isEmailConfirmed: false,
    isResetPassword: false,
    userdata: [],
    projects: []
};

// POST
export const addEducation = createAsyncThunk(
    "/profile/education",
    async (data, { dispatch, rejectWithValue }) => {
        try {
            const response = await customFetch.post("/profile/education", data);
            if (response.data && response.data.success) {
                dispatch(toggleEducationModal());
                toast.success("Added successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;

        } catch (error) {
            if (error) {
                const errorMessage = error.response?.data?.error || error.message;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return rejectWithValue(error.response.data.msg);
        }
    }
);

// PATCH
export const updateEducation = createAsyncThunk(
    "/profile/education",
    async (data, { dispatch, rejectWithValue }) => {
        try {

            const response = await customFetch.patch(`/profile/education/${data.id}`, data);
            if (response.data && response.data.success) {
                dispatch(toggleEducationUpdate());
                toast.success("Updated successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            if (error) {
                const errorMessage = error.response?.data?.error || error.message;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            return rejectWithValue(error.response.data.msg);
        }
    }
);

//DELETE
export const deleteEducation = createAsyncThunk(
    "/profile/education:id",
    async (data, { dispatch, rejectWithValue }) => {
        try {

            const response = await customFetch.delete(`/profile/education/${data.data.id}`);
            if (response.data && response.data.success) {
                //dispatch(toggleEducationUpdate());
                toast.success("Deleted successfully!", {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            return response.data;
        } catch (error) {
            if (error) {
                const errorMessage = error.response?.data?.error || error.message;
                toast.error(errorMessage, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            rejectWithValue(error.response.data.msg);
        }
    }
);



const projectSlice = createSlice({
    name: "projectSlice",
    initialState,
    extraReducers: (builder) => {
        builder
            //POST
            .addCase(addEducation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addEducation.fulfilled, (state, action) => {
                state.isLoading = false;
                window.location.reload();
            })
            .addCase(addEducation.rejected, (state, action) => {
                state.isLoading = false;

                toast.error(action.payload)
            })
            //PATCH
            .addCase(updateEducation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateEducation.fulfilled, (state, action) => {
                state.isLoading = false;
                window.location.reload();
            })
            .addCase(updateEducation.rejected, (state, action) => {
                state.isLoading = false;

                toast.error(action.payload)
            })
            //DELETE
            .addCase(deleteEducation.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deleteEducation.fulfilled, (state, action) => {
                state.isLoading = false;
                window.location.reload();
            })
            .addCase(deleteEducation.rejected, (state, action) => {
                state.isLoading = false;

                toast.error(action.payload)
            })
    },
});

export default projectSlice.reducer;