// @ts-nocheck
import { useEffect } from "react";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getStackQuestions,
  loadQuestionaire
} from "../../features/asessment/questions/questionSlice";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import NotFoundPage from '../common/NotFoundPage ';
import ErrorPage from "../../pages/404/ErrorPage";

const TechStack = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [process, setProcess] = useState(0);

  useEffect(() => {
    dispatch(loadQuestionaire());
  }, [dispatch]);

  useEffect(() => {
    const progress = parseInt(sessionStorage.getItem("progress"));
    setProcess(progress || 0);
  }, []);

  const { questionaire, isLoading } = useSelector(
    (state) => state.questionaire
  );

  if (isLoading) {
    return <div className="animate-spin">..</div>;
  }

  const handleQuestions = (stack) => {
    dispatch(getStackQuestions({ stack }));
    navigate(`/af4d-d11540a83f46/${stack}`);
  };

  return (
    <>
      <ToastContainer />
      {process === 100 ?  
      <div className="min-w-screen">
        <span>Go to Tech Stack Questions</span>
        {questionaire.length ? (
          questionaire.map((item) => (
            <div
              key={item.id}
              onClick={() => handleQuestions(item.stack)}
              className="flex items-center justify-between bg-white p-2 m-2 rounded hover:cursor-pointer"
            >
              <button>
                <span className="font-semibold text-sm">{item.stack}</span>
              </button>
              <MdOutlineArrowForwardIos className="text-2xl m-1 bg-white" />
            </div>
          ))
        ) : (
          <span className="flex justify-center items-center font-mono text-xl animate-bounce text-red-600">
            Questionaire is Unvailable at the Moment!
          </span>
        )}
      </div>
        : <ErrorPage message="You must finish your profile first"/>}
    </>
  );
};

export default TechStack;
