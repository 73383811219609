import React from 'react'
import final from '../../../assets/images/final.svg'
import { Link } from 'react-router-dom'
const Finished = () => {
    return (
        <div className="h-full grid place-items-center">
            <img src={final} alt="finished" className='animate-pulse' />
            <p className='text-xl font-semibold'>Congratulations! I know you'll be successful wherever you go, best wishes for this new job opportunity. Congrats on this next step in your career and all of the growth, connections and opportunities that come with it. I'm so lucky that I can celebrate this next step in your career with you. </p>
                <Link to="/sFTzWpRloUGLbeXER5P6RA"
                    className="text-center bg-blue-800 hover:bg-blue-900 focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-opacity-50 text-white font-medium py-2 px-4 rounded-lg w-full transition-colors duration-300 ease-in-out mb-4">
                    Back to Client Requests
                </Link>
        </div>


    )
}

export default Finished