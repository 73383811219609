import React from "react";
import { FaChevronCircleRight } from "react-icons/fa";
import TechStack from "../../components/TechnicalQuiz/TechStack";
import { Spacer } from "../Profile";

const TechnicalQuiz = () => {
  return (
    <>
      <div className="h-screen overflow-x-hidden overflow-y-scroll mt-20">
        <div className="p-3 bg-gray-200 rounded-md">
          <div className="m-3 flex flex-col">
            <h2 className="text-4xl font-bold ">Multiple Choice Questions</h2>
            <p className="font-bold text-sm py-3">
              Become eligible for top software jobs by completing these steps:
            </p>
            <p className="py-3">
              Are you interested in using these skills? This is our way of
              knowing which jobs to match you with. Please take quizzes for the
              skills you want to continue using in your next career.
            </p>
            <div className="flex flex-row">
              <FaChevronCircleRight className="text-blue-700 m-1" />
              <p> Take a quiz from your preferred tech stack.</p>
            </div>
            <div className="flex flex-row">
              <FaChevronCircleRight className="text-blue-700 m-1" />
              <p>Pass all quizzes from one tech stack.</p>
            </div>
            <h2 className="text-2xl font-bold py-3">Multiple Choice Quizzes</h2>

            <TechStack />
          </div>
        </div>
        <Spacer />
        <Spacer />
      </div>
    </>
  );
};

export default TechnicalQuiz;
