// @ts-nocheck
import { Formik, Form, ErrorMessage, Field } from 'formik';
import Select from 'react-select';
import * as Yup from 'yup';
import { Currency, Notice, Payment } from '../../../Utils/data';
import { useDispatch, useSelector } from 'react-redux';
import { addHiringPreference, updateHiringPreference } from '../../../features/profile/hiringSlice';
import { ToastContainer } from 'react-toastify';
import { FaSpinner } from 'react-icons/fa';
import { TiTickOutline } from 'react-icons/ti';
import { useNavigate, useParams } from 'react-router-dom';

const validationSchema = Yup.object().shape({

  tech_stack: Yup.array().min(1, "Select at least one technology stack").required("This field is required"),
  roles: Yup.array().min(1, "Select at least one roles").required("This field is required"),
  model_of_work: Yup.array().min(1, "Select at least one mode of work").required("This field is required"),
  job_type: Yup.array().min(1, "Select at least one job  type").required("This field is required"),
  job_engagement: Yup.array().min(1, "Select at least one job engagement").required("This field is required"),
  currency: Yup.string().required('Currency period cannot be null'),
  notice: Yup.string().required('Notice period cannot be null'),
  amount: Yup.string().required('Currency period cannot be null'),
  payment: Yup.string().required('payment period cannot be null'),
});

const HiringPreference = () => {

  const { mode } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hiring_preference } = useSelector((store) => store.user.profiledata);
  console.table(JSON.stringify(hiring_preference));
  const initialValues = {

    tech_stack: [],
    roles: [],
    model_of_work: [],
    job_type: [],
    job_engagement: [],


    actively_job_seeking: false,
    notice: '',
    currency: '',
    amount: '',
    payment: ''
  };

  const onSubmit = (values, onSubmitProps) => {
    const data = {
      actively_job_seeking: values.actively_job_seeking,
      tech_stack: values.tech_stack,
      roles: values.roles,
      model_of_work: values.model_of_work,
      job_type: values.job_type,
      job_engagement: values.job_engagement,
      notice_period: values.notice,
      expected_salary: `${values.amount} ${values.currency} ${values.payment}`
    }
    mode ? dispatch(updateHiringPreference(data)) : dispatch(addHiringPreference(data))
    navigate('/2tfSroM9CkWsP2oCLkmQMA')
  };

  return (
    <>
      <ToastContainer />
      <div className="p-0">
        <div className="w-full p-3 mt-20 bg-gray-200 lg:h-[85vh] mb-[15vh] rounded">
          <h3 className="font-bold text-2xl text-blue-900">Hiring Preferences</h3>
          <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ values, errors, touched, setFieldValue, isSubmitting }) => (
              <Form>
                <div className="py-4 flex justify-start items-center space-x-3">
                  <span className="text-gray-900 font-semibold">
                    Actively Job Seeking
                  </span>
                  <label className="relative inline-flex items-center mr-5 cursor-pointer">
                    <Field
                      type="checkbox"
                      name="actively_job_seeking"
                      className="sr-only peer"
                    />
                    <div className="w-12 h-6 bg-gray-200 rounded-full peer ring-1 ring-blue-800
                peer-checked:after:translate-x-full peer-checked:after:border-white after:content-['']
                after:absolute after:top-0.5 after:left-[2px] after:bg-white
                after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all
                dark:border-gray-600 peer-checked:bg-blue-800"
                    ></div>
                  </label>
                </div>


                <div className="gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                  <div className="m-1 bg-slate-50 p-3">
                    <span className="text-gray-900 font-semibold py-3">{`Technology Stack(s)`}</span>
                    {touched.tech_stack && errors.tech_stack && (
                      <div className="text-red-500 text-sm mt-1">{errors.tech_stack}</div>
                    )}
                    <div className="flex items-center m-2">
                      <Field
                        id="tech_stack"
                        name="tech_stack"
                        type="checkbox"
                        value="MEAN"
                        checked={values.tech_stack.includes("MEAN")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />

                      <label
                        htmlFor="tech_stack"
                        className="ml-2 text-sm text-gray-900"
                      >
                        MEAN
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="tech_stack"
                        name="tech_stack"
                        type="checkbox"
                        value="ASP.NET"
                        checked={values.tech_stack.includes("ASP.NET")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />

                      <label
                        htmlFor="tech_stack"
                        className="ml-2 text-sm text-gray-900"
                      >
                        ASP.NET
                      </label>
                    </div>

                    <div className="flex items-center m-2">
                      <Field
                        id="tech_stack"
                        name="tech_stack"
                        type="checkbox"
                        value="MEVN"
                        checked={values.tech_stack.includes("MEVN")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label
                        htmlFor="tech_stack"
                        className="ml-2 text-sm text-gray-900"
                      >
                        MEVN
                      </label>
                    </div>

                    <div className="flex items-center m-2">
                      <Field
                        id="tech_stack"
                        name="tech_stack"
                        type="checkbox"
                        value="LAMPP"
                        checked={values.tech_stack.includes("LAMPP")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />

                      <label
                        htmlFor="tech_stack"
                        className="ml-2 text-sm text-gray-900"
                      >
                        LAMPP
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="tech_stack"
                        name="tech_stack"
                        type="checkbox"
                        value="MERN "
                        checked={values.tech_stack.includes("MERN ")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />

                      <label
                        htmlFor="tech_stack"
                        className="ml-2 text-sm text-gray-900"
                      >
                        MERN
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="tech_stack"
                        name="tech_stack"
                        type="checkbox"
                        value="Ruby on Rails "
                        checked={values.tech_stack.includes("Ruby on Rails ")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />

                      <label
                        htmlFor="tech_stack"
                        className="ml-2 text-sm text-gray-900"
                      >
                        Ruby on Rails
                      </label>
                    </div>
                  </div>
                  <div className="m-1 bg-slate-50 p-3 rounded ">
                    <span className="text-gray-900 font-semibold py-3">{`roles(s)`} </span>
                    {touched.roles && errors.roles && (
                      <div className="text-red-600 text-sm mt-1">{errors.roles}</div>
                    )}
                    <div className="flex items-center m-2">
                      <Field
                        id="roles"
                        name="roles"
                        type="checkbox"
                        value="Software Engineer"
                        checked={values.roles.includes("Software Engineer")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="roles" className="ml-2 text-sm text-gray-900">
                        Software Engineer
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="roles"
                        name="roles"
                        type="checkbox"
                        value="Data Engineer"
                        checked={values.roles.includes("Data Engineer")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="roles" className="ml-2 text-sm text-gray-900">
                        Data Engineer
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="roles"
                        name="roles"
                        type="checkbox"
                        value="Frontend Engineer"
                        checked={values.roles.includes("Frontend Engineer")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="roles" className="ml-2 text-sm text-gray-900">
                        Frontend Engineer
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="roles"
                        name="roles"
                        type="checkbox"
                        value="Backend Engineer"
                        checked={values.roles.includes("Backend Engineer")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="roles" className="ml-2 text-sm text-gray-900">
                        Backend Engineer
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="roles"
                        name="roles"
                        type="checkbox"
                        value="Fullstack Engineer"
                        checked={values.roles.includes("Fullstack Engineer")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="roles" className="ml-2 text-sm text-gray-900">
                        Fullstack Engineer
                      </label>
                    </div>
                  </div>

                  <div className="m-1 bg-slate-50 p-3 rounded ">
                    <span className="text-gray-900 font-semibold py-3">{`Mode of Work(s)`} </span>
                    {touched.model_of_work && errors.model_of_work && (
                      <div className="text-red-600 text-sm mt-1">{errors.model_of_work}</div>
                    )}
                    <div className="flex items-center m-2">
                      <Field
                        id="model_of_work"
                        name="model_of_work"
                        type="checkbox"
                        value="Onsite"
                        checked={values.model_of_work.includes("Onsite")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="model_of_work" className="ml-2 text-sm text-gray-900">
                        Onsite
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="model_of_work"
                        name="model_of_work"
                        type="checkbox"
                        value="Remote"
                        checked={values.model_of_work.includes("Remote")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="model_of_work" className="ml-2 text-sm text-gray-900">
                        Remote
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="model_of_work"
                        name="model_of_work"
                        type="checkbox"
                        value="Hybrid"
                        checked={values.model_of_work.includes("Hybrid")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="model_of_work" className="ml-2 text-sm text-gray-900">
                        Hybrid
                      </label>
                    </div>
                  </div>


                  <div className="m-1 bg-slate-50 p-3 rounded ">
                    <span className="text-gray-900 font-semibold py-3">{`Job Type(s)`} </span>
                    {touched.job_type && errors.job_type && (
                      <div className="text-red-600 text-sm mt-1">{errors.job_type}</div>
                    )}
                    <div className="flex items-center m-2">
                      <Field
                        id="job_type"
                        name="job_type"
                        type="checkbox"
                        value="Permanent"
                        checked={values.job_type.includes("Permanent")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="job_type" className="ml-2 text-sm text-gray-900">
                        Permanent
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="job_type"
                        name="job_type"
                        type="checkbox"
                        value="Contract"
                        checked={values.job_type.includes("Contract")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="job_type" className="ml-2 text-sm text-gray-900">
                        Contract
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="job_type"
                        name="job_type"
                        type="checkbox"
                        value="Temporary"
                        checked={values.job_type.includes("Temporary")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="job_type" className="ml-2 text-sm text-gray-900">
                        Temporary
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="job_type"
                        name="job_type"
                        type="checkbox"
                        value="Internship"
                        checked={values.job_type.includes("Internship")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="job_type" className="ml-2 text-sm text-gray-900">
                        Internship
                      </label>
                    </div>
                  </div>


                  <div className="m-1 bg-slate-50 p-3 rounded ">
                    <span className="text-gray-900 font-semibold py-3">{`Job Engagement(s)`} </span>
                    {touched.job_engagement && errors.job_engagement && (
                      <div className="text-red-600 text-sm mt-1">{errors.job_engagement}</div>
                    )}
                    <div className="flex items-center m-2">
                      <Field
                        id="job_engagement"
                        name="job_engagement"
                        type="checkbox"
                        value="Part time"
                        checked={values.job_engagement.includes("Part time")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="job_engagement" className="ml-2 text-sm text-gray-900">
                        Part time
                      </label>
                    </div>
                    <div className="flex items-center m-2">
                      <Field
                        id="job_engagement"
                        name="job_engagement"
                        type="checkbox"
                        value="Full time"
                        checked={values.job_engagement.includes("Full time")}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600"
                      />
                      <label htmlFor="job_engagement" className="ml-2 text-sm text-gray-900">
                        Full time
                      </label>
                    </div>
                  </div>

                </div>




                <div className="gap-2 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                  <div className="flex flex-col p-1">
                    <label htmlFor="Notice Period" className="font-semibold text-gray-900 py-2">
                      Notice Period
                    </label>
                    <div className="flex flex-col py-1">
                      <Select
                        name="notice"
                        id="notice"
                        value={Notice.find((option) => option.value === values.notice)}
                        options={Notice}
                        onChange={(option) => setFieldValue('notice', option.value)}
                      />
                      <ErrorMessage name="notice" component="div" className="text-red-500 mt-1" />
                    </div>
                  </div>
                  <div className="flex flex-col p-1">
                    <label htmlFor="Currency" className="font-semibold text-gray-900 py-2">
                      Currency
                    </label>
                    <div className="flex flex-col py-1">
                      <Select
                        name="currency"
                        id="currency"
                        value={Currency.find((option) => option.value === values.notice)}
                        options={Currency}
                        onChange={(option) => setFieldValue('currency', option.value)}
                      />
                      <ErrorMessage name="currency" component="div" className="text-red-500 mt-1" />
                    </div>
                  </div>

                  <div className="flex flex-col p-1">
                    <label htmlFor="Amount" className="font-semibold text-gray-900 py-2">Amount</label>
                    <div className="flex flex-col py-1">
                      <Field
                        id="amount"
                        placeholder="Amount"
                        name="amount"
                        type="number"
                        className=" appearance-none border-2
                   border-gray-300 rounded py-1 px-3 text-gray-700 
                   focus:outline-none focus:bg-white focus:border-blue-500 w-full"
                      />
                    </div>
                    <ErrorMessage component="div" name="amount" className="text-sm text-end mr-2 text-red-500" />
                  </div>

                  <div className="flex flex-col p-1">
                    <label htmlFor="payment" className="font-semibold text-gray-900 py-2">Contract Type</label>
                    <div className="flex flex-col py-1">
                      <Select
                        name="payment"
                        id="payment"
                        value={Payment.find((option) => option.value === values.notice)}
                        options={Payment}
                        onChange={(option) => setFieldValue('payment', option.value)}
                      />
                      <ErrorMessage component="div" name="payment" className="text-sm text-end mr-2 text-red-500" />
                    </div>
                  </div>


                  <div className="w-full">
                    <label htmlFor="payment" className="select-none font-semibold text-gray-200 py-2">..</label>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className=" mt-1 w-full mr-1 text-center bg-blue-600 text-white rounded py-2 px-4  hover:bg-blue-700"
                    >
                      {isSubmitting ? (
                        <span className="flex flex-row px-2 justify-center items-center">
                          <FaSpinner className="animate-spin mr-3 h-5 w-5 text-white" />
                          <span>Processing</span>
                        </span>
                      ) : (
                        <span
                          className="flex flex-row space-x-1 font-semibold hover:font-bold justify-center items-center">
                          <TiTickOutline className="m-1" />
                          <span>Submit</span>
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default HiringPreference;
