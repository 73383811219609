// @ts-nocheck
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { getQuestionsResponse } from "../../features/asessment/questions/questionSlice";
import { Spacer } from "../../pages/Profile";

const TopicQuestion = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id, vFIuAwDCJrCwEGBFsJCCvtwFDutuAJAr } = useParams();
  const { topics,stack } = useSelector((state) => state.questionaire.stackQuestions);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [valuesOnly, setValuesOnly] = useState([]);

  const answers = {
    topic_id: id,
    stack_name:stack,
    answers: valuesOnly,
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const selectedValues = {};
    for (const [questionId, answer] of Object.entries(selectedAnswers)) {
      selectedValues[questionId] = answer.value;
    }
    const newValuesOnly = Object.values(selectedValues);

    if (newValuesOnly.length > 0) {
      dispatch(getQuestionsResponse(answers));
      navigate('/k7V1YWxwTNp7oPzJ')
    }

    setValuesOnly(newValuesOnly); // update the valuesOnly array state
  };

  const handleAnswerSelect = (questionId, choice) => {
    setSelectedAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: { value: choice },
    }));
  };

  const renderChoices = (questionId, choices) => {
    return choices.map((choice) => (
      <div className="flex items-center space-x-4 m-2 p-2">
        <input
          type="radio"
          id={`${questionId}-${choice}`}
          name={questionId}
          value={choice}
          onChange={() => handleAnswerSelect(questionId, choice)}
          checked={
            selectedAnswers[questionId] !== undefined &&
            selectedAnswers[questionId] !== null &&
            selectedAnswers[questionId].value === choice
          }
          required
          className="form-radio text-red-600"
        />
        <label
          htmlFor={`${questionId}-${choice}`}
          className="form-radio-label text-gray-700"
        >
          {choice}
        </label>
      </div>
    ));
  };

  const renderQuestions = () => {
    return (
      topics?.map((topic) => (
        <React.Fragment key={topic._id}>
          <h3 className="text-lg font-semibold mb-2">{topic.topic}</h3>
          {topic.questions
            .filter((question) => question.choices?.length > 0)
            .map((question) => (
              <div key={question._id} className="mb-2">
                <p className="py-2 text-xl">{question.question}</p>
                <div className="bg-gray-100 rounded-lg shadow-sm">
                  {renderChoices(question._id, question.choices)}
                </div>
              </div>
            ))}
        </React.Fragment>
      )) || (
        <>
          <span className="flex justify-center items-center font-mono text-red-600 text-xl">
            No Questions Available for this Topic
          </span>
        </>
      )
    );
  };
  
  const hasQuestions = topics.some((topic) =>
  topic.questions.some((question) => question.choices?.length > 0)
);


  return (
    <>
      <ToastContainer />
      <div className="h-screen overflow-x-hidden overflow-y-scroll p-3">
        <div className="p-3 bg-gray-200 rounded-md">
          <div className="flex justify-between items-center">
            <h2 className="text-3xl font-bold py-2 ">
              Topic: {vFIuAwDCJrCwEGBFsJCCvtwFDutuAJAr}
            </h2>
            <p className="text-sm font-semibold mr-3"> Questions</p>
          </div>
          <hr className="h-px my-8 bg-dark border-0" />
          <form onSubmit={handleSubmit}>
            <div className="flex flex-col">{renderQuestions()}</div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="inline-flex items-center px-5 py-2.5 text-md font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800"
              >
                Submit Answers
              </button>
            </div>
          </form>
        </div>
        <Spacer />
        <Spacer />
      </div>
    </>
  );
};

export default TopicQuestion;
