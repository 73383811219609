import React from "react";

function ProgressItem(props) {
  const { completed, title, date } = props;

  const completedClass = completed ? "bg-yellow-400" : "bg-yellow-400";
  const completedRing = completed ? "ring-blue-900" : "ring-blue-800";

  return (
    <li className="mb-10 ml-6">
      <span
        className={`p-left absolute flex items-center justify-center w-3 h-3 rounded-full -left-4 ring-1 ${completedRing} ${completedClass}`}
      ></span>
      <h3 className="font-semibold leading-tight text-black text-sm">
        {title}
      </h3>
      <span className="text-sm font-mono text-blue-800 leading-tight">
        {date}
      </span>
      <hr className="h-px my-3 bg-black border-0" />
    </li>
  );
}

export default ProgressItem;
