// @ts-nocheck
import React, { useEffect, useState } from "react";
import { Stepper, ProgressBar } from "../../pages/Dashboard/Sections";
import Welcome from "../../pages/Dashboard/Sections/Welcome";
import Default from "../../pages/Dashboard/Sections/Default";

const StatsInfo = () => {
  const [process, setProcess] = useState(0);

  useEffect(() => {
    const progress = parseInt(sessionStorage.getItem("progress"));
    setProcess(progress || 0);
  }, []);

  return (
    <>
      <div className="grid h-[89vh] lg:grid-cols-2 gap-6 overflow-y-auto">
        <div className="lg:col-span-1 flex flex-col rounded-3xl h-full space-y-6">
          <div className="rounded-xl">
            {process === 100 ? <Welcome /> : <Default />}
          </div>
          <div className="w-full p-7 bg-gray-200 flex justify-center items-center rounded-xl">
            <ProgressBar />
          </div>
        </div>
        <div className="lg:col-span-1 p-5 lg:p-2 bg-gray-200 flex justify-start rounded-3xl">
          <Stepper />
        </div>
      </div>
    </>
  );
};

export default StatsInfo;
