// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import { toggleDeleteModal, toggleExperienceModal, toggleUpdateModal } from "../layout/ModalSlice";



const initialState = {
  isLoading: false,
  status: "idle",
  error: null,
  experience: [],
};
//POST Experience
export const addWorkExperience = createAsyncThunk(
  "profile/experience",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.post("/profile/experience", data);
      if (response.data && response.data.success) {
        dispatch(toggleExperienceModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.message || error.response?.data?.error;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

//PATCH Experience
export const updateWorkExperience = createAsyncThunk(
  "profile/experience",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.patch(`/profile/experience/${data.id}`, data);
      if (response.data && response.data.success) {
        dispatch(toggleUpdateModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

//DELETE Experience
export const deleteWorkExperiencen = createAsyncThunk(
  "/profile/experience:id",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.delete(`/profile/experience/${data.data.id}`);
      if (response.data && response.data.success) {
        dispatch(toggleDeleteModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);

const experienceSlice = createSlice({
  name: "Experience",
  initialState,
  addExperience: (state, action) => {
    state.experience.push(action.payload);
  },
  extraReducers: (builder) => {
    builder
      //addNew
      .addCase(addWorkExperience.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addWorkExperience.fulfilled, (state, action) => {

        state.isLoading = false;
        toast.success(`Added Successfull`);
      })
      .addCase(addWorkExperience.rejected, (state, action) => {

        state.isLoading = false;
        toast.error(action.payload);
      })
      //update
      .addCase(updateWorkExperience.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateWorkExperience.fulfilled, (state, action) => {

        toast.success(`Updated Successfull`);
      })
      .addCase(updateWorkExperience.rejected, (state, action) => {
        state.isLoading = false;
        toast.error("Not Updated", action.payload);
      })
  },
});
export const { addExperience } = experienceSlice.actions;
export default experienceSlice.reducer;
