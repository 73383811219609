import React from 'react'

const TalentRequestData = () => {
  return (
    <>
      <div className='p-3 h-fit '>
        {/* <DebugData data={clientRequest} /> */}
        <div className='grid grid-cols-3 gap-3'>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Role</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Deadline</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Experience Level</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-3'>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Technology Stacks</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Country</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Years of Experience</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-3'>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Job Engagement</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Hiring TimeZone</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Job Type</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-3'>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Salary Range</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col p-3 m-1 bg-white'>
            <p className='font-semibold  text-blue-800'>Availability</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
          <div className='flex flex-col bg-white p-3 m-1'>
            <p className='font-semibold  text-blue-800'>Number of Positions</p>
            <p className='text-sm font-mono text-gray-900'></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default TalentRequestData