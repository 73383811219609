import React from "react"
import { } from "react-icons/io"

const MyLanguages = () => {
    return (
        <>
            <div className='flex flex-col p-2 m-2'>
                <div className='flex flex-row space-x-3 m-4'>
                    <h3 className='text-black text-2xl font-semibold mt-4'>Qualified Stacks</h3>
                </div>
                {/* <div className="flex flex-row space-x-3">
                    <span>MEAN</span>
                    <div className="flex flex-start bg-white overflow-hidden w-full h-1.5 rounded-sm font-sans text-xs font-medium border mt-2">
                        <div className="flex justify-center items-baseline h-full overflow-hidden break-all bg-blue-800 text-gray-400" style={{ width: '50%' }}>
                        </div>
                    </div>
                </div> */}
            </div>
        </>

    )
}

export default MyLanguages