const techstacks = [
    { value: 'Java', label: 'Java' },
    { value: 'React', label: 'React' },
    { value: 'Nodejs', label: 'NodeJs' },
    { value: 'Github', label: 'Github' },
    { value: 'Python', label: 'Python' },
    { value: 'AWS', label: 'AWS' },
    { value: 'Docker', label: 'Docker' },
    { value: 'MySQL', label: 'MySQL' },
    { value: 'HTML', label: 'HTML' },
    { value: 'CSS', label: 'CSS' },
    { value: 'JavaScript', label: 'JavaScript' },
    { value: 'Vue', label: 'Vue' },
    { value: 'Angular', label: 'Angular' },
    { value: 'Bootstrap', label: 'Bootstrap' },
    { value: 'jQuery', label: 'jQuery' },
    { value: 'MongoDB', label: 'MongoDB' },
    { value: 'Redis', label: 'Redis' },
    { value: 'PostgreSQL', label: 'PostgreSQL' },
    { value: 'GraphQL', label: 'GraphQL' },
    { value: 'Kubernetes', label: 'Kubernetes' },
];


export default techstacks