import React from 'react';
import Helmet from '../../common/Helmet';

const TalentInterview = () => {
  return (
    <>
      <Helmet title="Talent Interview" />
      <div className="flex justify-center items-center flex-col h-full">
        <div className="max-w-2xl p-4 bg-white border border-gray-300 rounded shadow">
          <p className="text-lg font-bold">Dear Talent,</p>
          <p className="my-4">
            We hope this message finds you well. We want to inform you that we have scheduled interviews for potential opportunities. Please check your emails regularly for any communication regarding interview schedules. Stay prepared and best of luck!
          </p>
          <p className="font-semibold">
            <span>Kind regards,</span>
            <br />
            <span>Savabyte Talent Acquisition Team</span>
          </p>
        </div>
      </div>
    </>
  );
};

export default TalentInterview;
