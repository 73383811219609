import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import customFetch from "../../../Utils/hooks/axios";

const initialState = {
  isLoading: false,
  questionaire: [],
  stackQuestions: [],
  questionResponse: {},
  questionResults: []
};

export const loadQuestionaire = createAsyncThunk(
  "talent/questionaire",
  async (_, { rejectWithValue }) => {
    try {
      const response = await customFetch.get("/questionare");

      if (response.data && response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getStackQuestions = createAsyncThunk(
  "questionaire/stack",
  async (data, { rejectWithValue }) => {
    try {

      const response = await customFetch.get(`/questionare/${data.stack}`);
      if (response.data && response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const fetchResults = createAsyncThunk(
  "questionare/topics/scores",
  async (data, { rejectWithValue }) => {
    try {

      const response = await customFetch.get(`/questionare/topics/scores`);
      if (response.data && response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getQuestionsResponse = createAsyncThunk(
  "/questionare/response",
  async (data, { rejectWithValue }) => {
    try {
      const response = await customFetch.post(`/questionare/response`, data);

      if (response.data && response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
        return rejectWithValue(errorMessage);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

const questionSlice = createSlice({
  name: "questionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      //Questionaire
      .addCase(loadQuestionaire.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loadQuestionaire.fulfilled, (state, action) => {

        state.questionaire = action.payload.data;
        state.isLoading = false;
      })
      .addCase(loadQuestionaire.rejected, (state) => {
        state.isLoading = false;
      })

      //Stack Questions
      .addCase(getStackQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStackQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.stackQuestions = action.payload.data;
      })
      .addCase(getStackQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      //Question Response
      .addCase(getQuestionsResponse.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestionsResponse.fulfilled, (state, action) => {

        state.questionResponse = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getQuestionsResponse.rejected, (state) => {
        state.isLoading = false;
      })
      //getResults
      .addCase(fetchResults.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchResults.fulfilled, (state, action) => {

        state.questionResults = action.payload.data;
        state.isLoading = false;
      })
      .addCase(fetchResults.rejected, (state) => {
        state.isLoading = false;
      })
  },
});

export default questionSlice.reducer;
