import React from 'react'

const Spacer = () => {
    return (
        <>
          <div className="p-1 abt-top md:abt-top lg:about-top"></div>
        </>
      )
}

export default Spacer