// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { RiDeleteBin5Fill } from 'react-icons/ri';
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Helmet from '../../common/Helmet';
import { AiOutlineFilePdf } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux';
import { getTalentNDA } from '../../../features/talentRequest/talentRequestSlice';
import customFetch from '../../../Utils/hooks/axios';

const TalentNDA = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const dispatch = useDispatch();
  const data = useParams()
  const { id } = data;
  const { talent_nda } = useSelector((store) => store.talentRequest.nda) || {};

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleDelete = () => {
    setSelectedFile(null);
  };
  useEffect(() => {
    dispatch(getTalentNDA(id))
  }, [dispatch, id])

  const handleSubmit = () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('client_nda', selectedFile);

      customFetch.patch(`/profile/client_requests/${id}/nda`, formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch(error => {
          const errorMessage =
            error.response.data.error || error.response.data.message || error.message;
          toast.error(errorMessage, {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="">
        <div className="p-3 ">
          <Helmet title="Clients are requested to sign a Non-Disclosure Agreement, ensuring the confidentiality and protection of their sensitive information. This step provides clients with reassurance that their proprietary data and project details will remain secure throughout the talent acquisition process, fostering a trusted and secure partnership with Savabyte." />
          <div className='bg-white p-3 mt-3'>
            <div className="flex flex-col p-3bg-white">
              <div className='underline'>
                <a href={talent_nda} target="_blank" rel="noopener noreferrer">

                  <div className='flex space-x-3 cursor-pointer'>
                    <span><AiOutlineFilePdf className='text-red-600 text-2xl' /></span>
                    <span className='text-sm font-medium text-gray-900'>Unsigned NDA</span>
                  </div>
                </a>
              </div>
              <div className="w-5/6 space-y-3">
                <label htmlFor="file_input" className="block mb-3 mt-3 text-sm font-medium text-gray-900">
                  Signed Client NDA
                </label>
                <div className="flex">
                  <input
                    id="file_input"
                    type="file"
                    className="block w-full text-sm text-blue-900 border border-blue-800 rounded-lg cursor-pointer bg-white focus:outline-none"
                    onChange={handleFileChange}
                  />
                  {selectedFile && (
                    <div className="mt-3 px-3">
                      <RiDeleteBin5Fill size={24} onClick={handleDelete} className="text-red-600" />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-5 p-3">
              <button className="w-full font-semibold bg-blue-800 text-white py-2 px-4 rounded-md hover:bg-blue-900 focus:outline-none" onClick={handleSubmit}>
                Send NDA
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentNDA;
