// App.jsx
import React, { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FallBackLoader from "./components/common/FallBackLoader";
import {
  StatsInfo,
  Profile,
  TechnicalQuiz
} from "./components/dashboard";
import TopicQuestions from "./components/TechnicalQuiz/TopicQuestions";
import TopicQuestion from "./components/TechnicalQuiz/TopicQuestion";
import QuizResults from "./pages/Results/QuizResults";
import TopicResults from "./pages/Results/TopicResults";
import HiringPreference from "./components/profile/HiringPreference/HiringPreference";
import TalentRequests from "./components/TalentRequests/TalentRequests";
import TalentRequestDetails from "./components/TalentRequests/TalentRequestDetails";
import customFetch from "./Utils/hooks/axios";

const Register = lazy(() => import("./pages/Auth/Register"));
const Login = lazy(() => import("./pages/Auth/Login"));
const ErrorPage = lazy(() => import("./pages/404/ErrorPage"));
const ConfirmEmail = lazy(() => import("./pages/Auth/ConfirmEmail"));
const ResetPassword = lazy(() => import("./pages/Auth/ResetPassword"));
const Dashboard = lazy(() => import("./pages/Dashboard/Dashboard"));
const ForgotPassword = lazy(() => import("./pages/Auth/ForgotPassword"));

customFetch.interceptors.response.use(
  (response) => response,
  (error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.name === "TokenExpiredError"
    ) {
      window.location.pathname = "/talent/l5T75heuRE2RYrQBOJb38Q";
    }
    return Promise.reject(error);
  });
function App() {
  return (
    <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
      <Suspense fallback={<FallBackLoader />}>
        <Routes>
          <Route path="/*" element={<Dashboard />}>
            <Route index exact element={<StatsInfo />} />
            <Route path="2tfSroM9CkWsP2oCLkmQMA" element={<Profile />} />
            <Route path="k7V1YWxwTNp7oPzJ" element={<QuizResults />} />
            <Route path="UpIZJyywQUSZXesLFGxPYg/:mode?" element={<HiringPreference />} />
            <Route path="1gS2hsd9xUuZc8U1e5DUug/:zErxDJ3ToEaoWaagF8Urvg?" element={<TopicResults />} />
            <Route path="af4d-d11540a83f46/:id?" element={<TopicQuestions />} />
            <Route path="af4d-d11540a83f46/:id?/deO7Cb0Ipk6y8cZed1wAFw/:vFIuAwDCJrCwEGBFsJCCvtwFDutuAJAr?" element={<TopicQuestion />} />
            <Route path="ff3Mj4fODEyqxozUumoQ7g" element={<TechnicalQuiz />} />
            <Route path="c4d2a26f5c01431da5e221215c767072" element={<TalentRequests />} />
            <Route path="b710ef70ab384c3fba0809c3f3ee0b6b/:id?" element={<TalentRequestDetails />} />
            <Route path="*" element={<ErrorPage message="Unauthorized" />} />
          </Route>
          <Route path="/wFOwcPE43020zVhgLX9psw" element={<Register />} />
          <Route path="/l5T75heuRE2RYrQBOJb38Q/:id?" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/confirmemail/:token?" element={<ConfirmEmail />} />
          <Route path="/resetpassword/:token?" element={<ResetPassword />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
