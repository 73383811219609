import React from 'react'
import { MyAwards, MyCertifications } from '../../../pages/Profile/sections'

const CertificationAwards = () => {
    return (
        <div className='w-full px-3'>
            <div className='row mt-6'>
                <div className='col bg-gray-200 text-black p-4 rounded m-2'>
                    <MyCertifications />
                </div>
                <div className='col bg-gray-200 text-black p-4 rounded m-2'>
                    <MyAwards />
                </div>
            </div>
        </div>
    )
}

export default CertificationAwards