import React from "react";
import { BsFillCalendar3WeekFill, BsFillSave2Fill, BsFillTelephoneOutboundFill } from 'react-icons/bs';
import { FaCalendarCheck } from 'react-icons/fa';
import TalentRequestData from "../../components/TalentRequests/Sections/TalentRequestData";
import TalentInterview from "../../components/TalentRequests/Sections/TalentInterview";
import TalentNDA from "../../components/TalentRequests/Sections/TalentNDA";
import OfferContract from "../../components/TalentRequests/Sections/OfferContract";
import TalentOnboarding from "../../components/TalentRequests/Sections/TalentOnboarding";

const talentMatching = [
    {
        label: "Client Request",
        value: "client request",
        icon: BsFillSave2Fill,
        desc: <TalentRequestData />,
    },
    {
        label: "Talent Interview",
        value: "talent interview",
        icon: BsFillTelephoneOutboundFill,
        desc: <TalentInterview />,
    },
    {
        label: "Talent NDA",
        value: "talent nda",
        icon: BsFillCalendar3WeekFill,
        desc: <TalentNDA />,
    },
    {
        label: "Offer Contract",
        value: "offer contract",
        icon: BsFillCalendar3WeekFill,
        desc: <OfferContract />,
    },
    {
        label: "Talent Onboarding",
        value: "talent onboarding",
        icon: FaCalendarCheck,
        desc: <TalentOnboarding />,
    },
];

export default talentMatching;
