// @ts-nocheck
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const TopicQuestions = () => {

  const { id } = useParams();
  const navigate = useNavigate();

  const { topics } = useSelector((state) => state.questionaire.stackQuestions);
  const handleNavigate = (id, vFIuAwDCJrCwEGBFsJCCvtwFDutuAJAr) => {
    navigate(`/af4d-d11540a83f46/${id}/deO7Cb0Ipk6y8cZed1wAFw/${vFIuAwDCJrCwEGBFsJCCvtwFDutuAJAr}`);
  }
  return (
    <>
      <ToastContainer />
      <div className="p-3 h-fit">
        <div className="p-3 bg-gray-200 rounded-md">
          <h2 className="text-3xl font-bold py-2 ">Technology Stack : {id}</h2>
          <div className="flex flex-col">
            <div className="flex flex-wrap">
              <div className="w-full md:w-1/2">
                {topics ? (
                  topics
                    .filter((item, index) => index % 2 === 0)
                    .map((item) => (
                      <div
                        key={item._id}
                        onClick={() => handleNavigate(item._id, item.topic_tittle)}
                        className="bg-blue-900 text-white p-2 m-2 rounded hover:cursor-pointer flex items-center justify-between"
                      >
                        <span>{item.topic_tittle}</span>
                        <MdOutlineArrowForwardIos className="text-2xl m-1 text-white" />
                      </div>
                    ))
                ) : (
                  <span className="flex justify-center items-center font-mono text-red-600 text-xl">
                    Topic Unvailable for this Stack
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopicQuestions;
