// @ts-nocheck
import React from "react";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTalentRequest } from "../../features/talentRequest/talentRequestSlice";
import talentMatching from "../../Utils/data/talentMatching";
import DebugData from '../common/DebugData';
import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";

const TalentRequestDetails = () => {
    const [activeTab, setActiveTab] = useState(
        sessionStorage.getItem("activeTab") || "dashboard"
    );

    const handleTabChange = (value) => {
        setActiveTab(value);
        sessionStorage.setItem("activeTab", value);
    };

    const { id } = useParams();
    const dispatch = useDispatch();
    const { request } = useSelector((state) => state.talentRequest);

    useEffect(() => {
        dispatch(getTalentRequest(id));
    }, [dispatch, id]);

    return (
        <>
        <ToastContainer />
            <div className="bg-gray-200 h-[90vh] rounded overflow-y-auto mt-20">
                <div className="p-3 space-y-3 bg-white">
                    {/* <DebugData data={request} /> */}
                    <Tabs
                        value={activeTab}
                        orientation="horizontal"
                        className="p-2 rounded"
                    >
                        <div className="flex flex-col gap-2 sm:flex-row">
                            <div className="w-full overflow-x-scroll md:overflow-hidden lg:w-1/6 xl:w-1/6 2xl:w-1/6">
                                <TabsHeader className="flex mt-3 sm:flex-row md:flex-col">
                                    {talentMatching.map(({ label, value, icon }) => (
                                        <React.Fragment key={value}>
                                            <Tab
                                                key={value}
                                                value={value}
                                                className="text-center"
                                                activeclassname="bg-yellow-400 text-black"
                                                onClick={() => handleTabChange(value)}
                                            >
                                                <div
                                                    className={`sm:flex md:flex-col items-center rounded p-2 text-sm font-semibold py-3 ${activeTab === value
                                                            ? "bg-yellow-400 text-black"
                                                            : "bg-gray-200 text-blue-800"
                                                        }`}
                                                >
                                                    <span className="flex px-4 space-x-3">
                                                        {React.createElement(icon, {
                                                            className: "w-5 h-5",
                                                        })}
                                                        <span>{label}</span>
                                                    </span>
                                                </div>
                                            </Tab>
                                            <span className="items-center justify-center hidden text-blue-800 sm:flex">
                                                |
                                            </span>
                                        </React.Fragment>
                                    ))}
                                </TabsHeader>
                            </div>
                            <div className="w-full lg:w-5/6 xl:w-5/6 2xl:w-5/6">
                                <TabsBody>
                                    {talentMatching.map(({ value, desc }) => (
                                        <TabPanel
                                            key={value}
                                            value={value}
                                            className={`pt-3 rounded bg-gray-200 ${activeTab === value ? "text-blue-800" : ""
                                                } h-[85vh]`}
                                        >
                                            {desc}
                                        </TabPanel>
                                    ))}
                                </TabsBody>
                            </div>
                        </div>
                    </Tabs>
                </div>
            </div>
        </>
    );
};

export default TalentRequestDetails;

