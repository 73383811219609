// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import { toggleAboutModal, toggleSocialModal, toggleSocialUpdate } from "../layout/ModalSlice";


const initialState = {
  isLoading: false,
  tokendata: "",
  data: {},
};

//POST about me
export const addAboutMe = createAsyncThunk(
  "profile/about",
  async (data, { rejectWithValue, dispatch }) => {
    //      addAboutMeThunk("/profile/about", data, thunkAPI);
    try {
      const response = await customFetch.post("/profile/about", data);
      if (response.data && response.data.success) {
        //dispatch(updateProfile(response.data.data.about))
        dispatch(toggleAboutModal());
        toast.success("Added successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

//PATCH about me
export const updateAboutMe = createAsyncThunk(
  "profile/about/",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.patch("/profile/about", data);
      if (response.data && response.data.success) {
        //dispatch(updateProfile(response.data.data.about))
        dispatch(toggleAboutModal());
        toast.success("Updated successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

//Social Accounts
export const addSocialAccount = createAsyncThunk(
  "profile/socials",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await customFetch.post("/profile/socials", data);
      if (response.data && response.data.success) {
        dispatch(toggleSocialModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);
export const updateSocialAccount = createAsyncThunk(
  "profile/socials/patch",
  async (data, { dispatch, rejectWithValue }) => {
    try {

      const response = await customFetch.patch("/profile/socials", data);
      if (response.data && response.data.success) {
        dispatch(toggleSocialUpdate());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error) {
        const errorMessage = error.response?.data?.error || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.message);
    }
  }
);

const userSlice = createSlice({
  name: "aboutDesciption",
  initialState,
  reducers: {
    updateProfile(state, action) {

      state.data = action.payload;
    },
    // other reducers
  },
  extraReducers: (builder) => {
    builder
      //addNew
      .addCase(addAboutMe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAboutMe.fulfilled, (state, action) => {
        toast.error(action.payload);
      })
      .addCase(addAboutMe.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload);
      })
      //update
      .addCase(updateAboutMe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAboutMe.fulfilled, (state, action) => {
        toggleAboutModal();
        toast.error(action.payload);
      })
      .addCase(updateAboutMe.rejected, (state, action) => {
        toast.error(action.payload);
      })

      .addCase(addSocialAccount.pending, (state) => {
      })
      .addCase(addSocialAccount.fulfilled, (state, action) => {
        state.isLoading = false;
      }).addCase(addSocialAccount.rejected, (state, action) => {
        toast.error(action.payload);
      })
  },
});

export const { updateProfile } = userSlice.actions;
export default userSlice.reducer;
