import { FiEdit } from "react-icons/fi";
import * as Yup from "yup";
import { toggleSocialModal, toggleSocialUpdate } from "../../../../features/layout/ModalSlice";
import { IoMdAdd, IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { FaSpinner } from "react-icons/fa";
import { TiTickOutline } from "react-icons/ti";
import { ErrorMessage, Field, Form, Formik } from "formik";
import Select from 'react-select'
import Modal from "../../../../components/common/Modal";
import { addSocialAccount, updateSocialAccount } from "../../../../features/profile/aboutSlice";
import socialAccounts from "../../../../Utils/data/socialAccounts";
import { useState } from "react";
import { SocialIcon } from "react-social-icons";

const MySocials = () => {

  const dispatch = useDispatch();
  const { isSocialsModalOpen, isEdit } = useSelector((store) => store.modal);
  const { socials } = useSelector((store) => store.user.profiledata);
  const [showModal, setShowModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };
  let existing;
  const title = `${isSocialsModalOpen ? "Add" : "Update"} social Accounts`;
  const initialValues = {
    link: "",
    socials: {
      twitter: '',
      linkedin: '',
      behance: '',
      github: '',
      stackoverflow: '',
    }
  };

  const handleSubmit = (values) => {
    const data = {
      icon: selectedOption.value.toLowerCase(),
      link: values.link
    }
    dispatch(addSocialAccount(data));
  };
  const handleUpdate = (values) => {


    const data = socials.map((social) => {
      if (social.icon === selectedOption.value.toLowerCase()) {
        return {
          ...social,
          link: values.link
        };
      }
      return social;
    });
    dispatch(updateSocialAccount(data));
  };
  const validationSchema = Yup.object({
    link: Yup.string().required("Url Cannot be null"),
  });
  const getNetworkName = (iconName) => {
    switch (iconName) {
      case "twitter":
        return "twitter";
      case "linkedin":
        return "linkedin";
      case "behance":
        return "behance";
      case "github":
        return "github";
      case "stackoverflow":
        return "stackOverflow";
      default:
        return "";
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row space-x-3 m-4">
          <h3 className="text-black text-xl font-semibold mt-4 flex-grow">
            Social Accounts
          </h3>
          <div className="flex ">
            <button
              onClick={() => {
                dispatch(toggleSocialModal());
              }}
              className="px-3 text-2xl hover:text-3xl mt-4"
              title="add account"
            >
              <IoMdAdd />
            </button>
            <button
              onClick={() => {
                dispatch(toggleSocialUpdate());
              }}
              className="text-xl text-blue-800 hover:text-xl mt-4"
              title="update account"
            >
              <FiEdit />
            </button>
          </div>
        </div>
        <div className="flex space-x-3 p-2">
          {socials?.map(icon => {
            let cleanedLink = icon.link.replace("http://localhost:3000/", "");
            return (
              <SocialIcon
                url={cleanedLink}
                network={getNetworkName(icon.icon)}
              />
            );
          })}

        </div>
      </div>
      {isSocialsModalOpen ? (
        <Modal
          showModal={showModal}
          setShowModal={toggleSocialModal}
          title={title}
        >
          <span className="select-none text-white">lorem http://localhost:3000/2tfSroM9CkWsP2oCLkmQMAhttp://localhost:3000/2tfSroM9CkWsP2oCLkmQMA</span>
          <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <div className="flex justify-between mx-auto max-w-6xl">
                  <Select
                    value={selectedOption}
                    onChange={handleChange}
                    options={socialAccounts}
                    className="flex-grow mr-3 w-40"
                  />
                  <div className="flex flex-col flex-grow ">
                    <Field
                      type="text"
                      name='link'
                      className="w-full appearance-none border-2
                       border-gray-200 rounded-lg py-2 px-4 text-gray-700 
                       leading-tight focus:outline-none focus:bg-white focus:border-blue-700"
                    />
                    <ErrorMessage
                      name="link"
                      component="div"
                      className="text-red-600 text-sm italic text-end mr-4"
                    />
                  </div>
                </div>

                <div className="flex items-center justify-between pt-4 mt-3">
                  <button
                    //onClick={() => dispatch(setShowModal())}
                    className="text-black-500 background-transparent px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    <button
                      onClick={() => dispatch(toggleSocialModal())}
                      className="flex flex-row space-x-1"
                    >
                      <IoMdClose className="m-1" />
                      <span>Cancel</span>
                    </button>
                  </button>
                  <button
                    type="submit"
                    className="bg-blue-800 hover:bg-blue-800 text-white py-2 px-4 rounded-lg inline-flex items-center"
                  >
                    {isSubmitting ? (
                      <div className="flex flex-row px-2">
                        <FaSpinner className="animate-spin mr-3 h-5 w-5 text-white" />
                        <span>processing</span>
                      </div>
                    ) : (
                      <div className="flex flex-row space-x-1 hover:font-bold">
                        <TiTickOutline className="m-1" />
                        <span>Save</span>
                      </div>
                    )}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : null}
      {/* Patching */}
      {isEdit ? (
        <Modal
          showModal={showModal}
          setShowModal={toggleSocialUpdate}
          title={title}
        >
          <span className="select-none text-white">lorem http://localhost:3000/2tfSroM9CkWsP2oCLkmQMAhttp://localhost:3000/2tfSroM9CkWsP2oCLkmQMA</span>
          <Formik
            initialValues={initialValues}
            onSubmit={handleUpdate}
            validationSchema={validationSchema}
          >
            {({ isSubmitting }) => (
              <Form>
                <>
                  <div className="flex justify-between mx-auto max-w-6xl">
                    <div className="mr-4">
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={socialAccounts}
                        className="flex-grow mr-3 w-40"
                      />
                    </div>
                    <div className="flex flex-col flex-grow ">
                      <Field
                        name="link"
                        type="text"
                        className=" text-sm w-full appearance-none border-2
                border-gray-200 rounded-lg py-2 px-4 text-gray-700 
                leading-tight focus:outline-none focus:bg-white focus:border-blue-700"
                      />
                    </div>
                  </div>
                </>
                <div className="flex items-center justify-between pt-4 mt-3">
                  <span
                    onClick={() => dispatch(setShowModal())}
                    className="text-black-500 background-transparent px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  >
                    <span
                      onClick={() => dispatch(toggleSocialUpdate())}
                      className="flex flex-row space-x-1"
                    >
                      <IoMdClose className="m-1" />
                      <span>Cancel</span>
                    </span>
                  </span>
                  <button
                    type="submit"
                    className="bg-blue-800 hover:bg-blue-800 text-white py-2 px-4 rounded-lg inline-flex items-center"
                  >
                    <div className="flex flex-row space-x-1 hover:font-bold">
                      <TiTickOutline className="m-1" />
                      <span>Update</span>
                    </div>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal>
      ) : null}
    </>
  )
}

export default MySocials