// @ts-nocheck
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch from "../../Utils/hooks/axios";
import { toast } from "react-toastify";
import axios from 'axios';
import {
  addEmailSent,
  addRegisterTokenToLocalStorage,
  getRegisterTokenFromLocalStorage,
  addTokenToLocalStorage,
  getUserTokenFromLocalStorage,
  getResetTokenFromLocalStorage
} from "../../services/localStorage";
import {
  logoutUserThunk,
} from "../thunks/userThunk";
import { toggleProfileModal } from "../layout/ModalSlice";
import { API_SERVER } from "../../constants/config";


const initialState = {
  isLoading: false,
  tokendata: "",
  datatoken: getUserTokenFromLocalStorage,
  isEmailConfirmed: false,
  isResetPassword: false,
  userdata: [],
  profiledata: [],
  projects: []
};

const userToken = getRegisterTokenFromLocalStorage();
const token = getUserTokenFromLocalStorage();
const resetToken = getResetTokenFromLocalStorage();

//User Registration
export const registerUser = createAsyncThunk(
  "user/registerUser",
  async (data, thunkAPI) => {
    try {

      const resp = await customFetch.post("/auth/signup", data);
      return resp.data;
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.message);
      } else {
        toast.error(error.message);
      }
      return thunkAPI.rejectWithValue(error.response.data.message);
    }
  }
);

//User Login
export const loginUser = createAsyncThunk(
  "user/login",
  async (data, { dispatch, rejectWithValue }) => {

    try {
      const resp = await customFetch.post("/auth/login", data);

      return resp.data.user;
    } catch (error) {
      if (error) {
        dispatch(toggleProfileModal())
        toast.error(error.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      return rejectWithValue(error.response.data.user.message);
    }
  }
);

//Forgot Password
export const forgotPassword = createAsyncThunk(
  "/auth/forgot-password",
  async (data, thunkAPI) => {
    try {
      debugger
      const response = await customFetch.post("auth/forgot-password", data);
      if (response.data && response.data.success) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        const errorMessage =
          error.response.data.error || error.response.data.message || error.message;
        toast.error(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

//Reset Password
export const ResetUserPassword = createAsyncThunk(
  "/auth/resetpassword",
  async (data, thunkAPI) => {
    try {

      const response = await customFetch.put(
        `auth/resetpassword/${resetToken}`,
        data
      );
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

//Confirm Email
export const ConfirmUserEmail = createAsyncThunk(
  "/auth/confirmemail",
  async (data, thunkAPI) => {
    try {

      const response = await customFetch.post(`auth/confirmemail/${token}`, {
        headers: {
          authorization: `Bearer  ${userToken}`,
        },
      });
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);
//Confirm Email
export const uploadAvatar = createAsyncThunk(
  "/profile/avatar",
  async (data, thunkAPI) => {
    try {

      const token = getUserTokenFromLocalStorage();
      const headers = {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      };
      const response = await axios.post(`${API_SERVER}/profile/avatar`, data, {
        headers: headers
      });
      const resp = response.data;
      return response.data;
    } catch (error) {
      toast.error(error.response.statusText);
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);


//Logout
export const logoutUser = logoutUserThunk(
  "user/logout",
  async (currentUser, thunkAPI) => {
    logoutUserThunk("auth/logout", currentUser, thunkAPI);
  }
);

export const getUserData = createAsyncThunk(
  "auth/me",
  async (_, thunkAPI) => {
    try {

      const response = await customFetch.get(`/profile`);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);


//Profile
export const updateUserProfile = createAsyncThunk(
  "/profile/account/details",
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await customFetch.patch("/profile/account/details", data);

      if (response.data && response.data.success) {
        dispatch(toggleProfileModal());
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      return response.data;
    } catch (error) {
      if (error.response) {
        toast.error(error.response.data.error, {
          position: toast.POSITION.POSITION.TOP_RIGH
        });
      } else {
        toast.error(error.message, {
          position: toast.POSITION.POSITION.TOP_RIGH
        });
      }
      return rejectWithValue(error.response.data.msg);
    }
  }
);





const userSlice = createSlice({
  name: "token",
  initialState,
  extraReducers: (builder) => {
    builder
      //login
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {

        state.isLoading = false;
        state.userdata = action.payload.data.user;
        const { token } = action.payload.data;
        state.tokendata = token
        addTokenToLocalStorage(token);
        toast.success(action.payload.message);
      })
      .addCase(loginUser.rejected, (state, action) => {


        state.isLoading = false;
        toast.error(action.payload);
        toast.warning("Login  " + action.meta.requestStatus)
      })

      //register
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {

        const { token, user } = action.payload.data;
        state.isLoading = false;
        state.token = JSON.stringify(token);
        state.userdata = user;
        addRegisterTokenToLocalStorage(token);
        toast.success(action.payload.message);
      })
      .addCase(registerUser.rejected, (state, action) => {
        const { error } = action.payload;

        state.isLoading = false;
        toast.success(error);
      })

      //forgotPassword
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(action.payload.message);
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isLoading = false;
      })

      //confirmEmail
      .addCase(ConfirmUserEmail.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ConfirmUserEmail.fulfilled, (state, action) => {
        const { token } = action.payload;
        state.isLoading = false;
        state.isEmailConfirmed = true;
        toast.success(`Email Confirmed Successfully`);
      })
      .addCase(ConfirmUserEmail.rejected, (state, action) => {
        state.isEmailConfirmed = false;
      })

      //reset password
      .addCase(ResetUserPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(ResetUserPassword.fulfilled, (state, action) => {
        const { token } = action.payload;
        state.isLoading = false;
        state.isResetPassword = true;
        toast.success(`Password reset Successfully`);
      })
      .addCase(ResetUserPassword.rejected, (state, action) => {
        state.isResetPassword = false;
      })
      .addCase(getUserData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getUserData.fulfilled, (state, action) => {

        state.profiledata = action.payload.data;
        state.isLoading = false;
      })
      .addCase(getUserData.rejected, (state, action) => {
        state.isLoading = false;
        toast.error(action.payload)
      })

      .addCase(updateUserProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {

        state.userdata = action.payload.data;
        state.isLoading = false;
        toast.success("Profile updated Successfully!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      })
      .addCase(updateUserProfile.rejected, (state, action) => {

        toast.error(action.payload)
      })

  },
});

export default userSlice.reducer;
