import React from "react"

const Welcome = () => {
    return (
        <>
            <div className="bg-welcome h-[30vh] w-full rounded-t-xl ">
                <div className="p-5 space-y-4">
                    <h3 className="text-white text-5xl font-semibold">Welcome to the SavaByte talent pool.</h3>
                    <p className="text-white text-xl ">We are excited to inform you that you are now eligible to be matched with hiring companies through our platform!</p>
                </div>
            </div>
        </>
    )
}

export default Welcome