import React from "react"

const Default = () => {
    return (
        <>
            <div className="bg-welcome h-[30vh] w-full rounded-t-xl ">
                <div className="p-5 space-y-4">
                    <h3 className="text-white text-5xl font-semibold">Finish Updating Your Profile</h3>
                </div>
            </div>
        </>
    )
}

export default Default