// @ts-nocheck
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchResults } from "../../features/asessment/questions/questionSlice";
import { ToastContainer } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import ErrorPage from "../404/ErrorPage";
import { useState } from "react";

const QuizResults = () => {

  const [process, setProcess] = useState(0);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchResults());
  }, [dispatch]);
  useEffect(() => {
    const progress = parseInt(sessionStorage.getItem("progress"));
    setProcess(progress || 0);
  }, []);
  const handlePerStack = (zErxDJ3ToEaoWaagF8Urvg) => {
    navigate(`/1gS2hsd9xUuZc8U1e5DUug/${zErxDJ3ToEaoWaagF8Urvg}`);
  };
  const { questionResults } = useSelector((state) => state.questionaire);
  return (
    <>
      <ToastContainer />
      <div className="h-screen mt-20">
        <div className="p-3 bg-gray-200 rounded-md h-full">
          <h2 className="text-3xl font-bold py-2 ">Completed Quizzes</h2>
          {
            process === 100 ?
              <div className="flex flex-col">
                <div className="flex flex-wrap">
                  <div className="w-full">
                    {questionResults ? (
                      <div>
                        {questionResults
                          .filter((item) => item.stack_name)
                          .map((item) => (
                            <div
                              key={item._id}
                              onClick={() => handlePerStack(item.stack_name)}
                              className="bg-white text-black p-2 m-2 rounded hover:cursor-pointer flex items-center justify-between"
                            >
                              <span className="font-semibold">
                                {item.stack_name}
                              </span>
                              <div className="flex space-x-3">
                                <button className="bg-green-500 rounded-lg text-white px-3 py-1">
                                  Completed {item.talent_score}/
                                  {item.total_stack_points}= Grade{" "}
                                  {Math.round(item.talent_score_percentage)}%
                                </button>
                                <div className="flex justify-center items-center">
                                  <MdArrowForwardIos className="text-2xl" />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ) : (
                      <span className="flex justify-center items-center font-mono text-red-600 text-xl">
                        Topic Unvailable for this Stack
                      </span>
                    )}
                  </div>
                </div>
              </div>
              :
              <ErrorPage message="Your profile is incomplete" />
          }
        </div>
      </div>
    </>
  );
};

export default QuizResults;




